// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPasswordWeb from "../../blocks/forgot-password/src/ResetPassword.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import LandingPageScreen from "../../blocks/landingpage/src/LandingPageScreen.web";
import HomeScreenWeb from "../../blocks/landingpage/src/HomeScreen.web"
import InvestorProfile from "../../blocks/email-account-registration/src/InvestorProfile.web";
import EmailVerification from "../../components/src/EmailVerification.web";
import ContentManagement from "../../blocks/contentmanagement/src/ContentManagement.web";
import EmailConfirmationWeb from "../../blocks/email-account-registration/src/EmailConfirmation.web";
import AccountSettingWeb from "../../blocks/landingpage/src/AccountSetting.web";
import StartupInvestmentWeb from "../../blocks/ordermanagement/src/StartupInvestment.web";
import FundInvestmentWeb from "../../blocks/ordermanagement/src/FundInvestment.web";
import SecondariesInvestmentWeb from "../../blocks/ordermanagement/src/SecondariesInvestment.web";
import InvestmentOpportunitiesWeb from "../../blocks/ordermanagement/src/InvestmentOpportunities.web";
import SecondariesInvestmentLiquidityWeb from "../../blocks/ordermanagement/src/SecondariesInvestmentLiquidity.web";
import OpportunitiesDetailsWeb from "../../blocks/ordermanagement/src/OpportunitiesDetails.web"
import PortfolioManagementWeb from "../../blocks/ordermanagement/src/PortfolioManagement.web"
import InvestmentProcessWeb from "../../blocks/ordermanagement/src/InvestmentProcess.web"


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const routeMap = {
  ForgotPassword: {
    component: ForgotPasswordWeb,
    path: "/ForgotPasswordWeb"
  },
  ResetPassword: {
    component: ResetPasswordWeb,
    path: "/ResetPasswordWeb"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/CompanyPolicy/:type",
  },
  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: "/EmailAccountRegistrationWeb"
  },
  EmailAccountLoginWeb: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginWeb"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  Home: {
    component: HomeScreen,
    path: '/HomeScreenWeb',
    exact: true
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  JoinWatchlistWeb: {
    component: LandingPageScreen,
    path: '/LandingPageScreen'
  },
  HomeScreenWeb: {
    component: HomeScreenWeb,
    path: '/',
    exact: true
  },
  EmailVerify: {
    component: EmailVerification,
    path: '/EmailVerify'
  },
  EmailConfirmation: {
    component: EmailConfirmationWeb,
    path: '/EmailConfirmation'
  },
  InvestorProfile: {
    component: InvestorProfile,
    path: '/InvestorProfile'
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/AboutUs"
  },
  Settings: {
    component: AccountSettingWeb,
    path: "/Settings"
  },
  StartupInvestmentWeb: {
    component: StartupInvestmentWeb,
    path: "/StartupInvestmentWeb"
  },
  FundInvestmentWeb: {
    component: FundInvestmentWeb,
    path: "/FundInvestmentWeb"
  },
  SecondariesInvestmentWeb: {
    component: SecondariesInvestmentWeb,
    path: "/SecondariesInvestmentWeb"
  },

  InvestmentOpportunitiesWeb: {
    component: InvestmentOpportunitiesWeb,
    path: "/InvestmentOpportunitiesWeb/:type"
  },

  OpportunitiesDetailsWeb: {
    component: OpportunitiesDetailsWeb,
    path: "/OpportunitiesDetailsWeb/:id"
  },

  SecondariesInvestmentLiquidityWeb: {
    component: SecondariesInvestmentLiquidityWeb,
    path: "/SecondariesInvestmentLiquidityWeb"
  },

  PortfolioManagementWeb: {
    component: PortfolioManagementWeb,
    path: "/PortfolioManagementWeb"
  },

  InvestmentProcessWeb: {
    component: InvestmentProcessWeb,
    path: "/InvestmentProcessWeb"
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh' }}>
        <ToastContainer />
        <div style={{ flex: 1 }}>
          {WebRoutesGenerator({ routeMap })}
        </div>
        <ModalContainer />
      </View>
    );
  }
}

export default App;