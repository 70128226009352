import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  email: string;
  emailError: string;

  //Reset Password 
  newPassword: string;
  confirmPassword: string;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  newPasswordError: string;
  confirmPasswordError: string;
  resetPasswordSuccess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    forgotPasswordApiCallId: string = "";
resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      loading: false,
      email: '',
      emailError: '',

      //Reset Password 
      newPassword: '',
      confirmPassword: '',
      showNewPassword: false,
      showConfirmPassword: false,
      newPasswordError: '',
      confirmPasswordError: '',
      resetPasswordSuccess: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.forgotPasswordApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
          localStorage.setItem("token", responseJson.meta.token);
          toast.success(responseJson.meta.message);
          setTimeout(() => {
            window.location.href = "/EmailAccountLoginWeb";
          }, 2000);
        } else {
          toast.error(responseJson.errors[0]);
        }
      } else if (apiRequestCallId === this.resetPasswordApiCallId) {
        this.setState({ loading: false });
        if (responseJson.data) {
          this.setState({
            resetPasswordSuccess: true,
          });
        } else {
          toast.error(responseJson.errors[0]);
        }
      }
    }
    // Customizable Area End
  }
  
  async componentDidMount() {
    // Customizable Area Star
    // Customizable Area End
  }

  // Customizable Area Start
handleEmailChange = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  validateEmail = () => {
    const { email } = this.state;
    if (!email) {
      this.setState({ emailError: "Email is required" });
      return false;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      this.setState({ emailError: "Invalid email address" });
      return false;
    }
    this.setState({ emailError: "" });
    return true;
  };

  handleSubmit = () => {
    const isEmailValid = this.validateEmail();

    if (!isEmailValid) {
      return;
    } else {
      this.ForgotPassword()
    }
  };

  validateNewPassword = () => {
    const { newPassword } = this.state;
    if (!newPassword) {
      this.setState({ newPasswordError: configJSON.pleaseEnterAPassword });
      return false;
    }
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(newPassword)) {
      this.setState({ newPasswordError: configJSON.placeholderIsPassword + ' must contain at least 8 characters, 1 special character, 1 number, 1 uppercase and 1 lowercase letter' });
      return false;
    }
    this.setState({ newPasswordError: "" });
    return true;
  };

  validateConfirmPassword = () => {
    const { newPassword, confirmPassword } = this.state;
    if (newPassword !== confirmPassword) {
      this.setState({ confirmPasswordError: "Your password doesn’t match" });
      return false;
    }
    this.setState({ confirmPasswordError: "" });
    return true;
  };

  handleNewPasswordChange = (event: any) => {
    this.setState({
      newPassword: event.target.value,
    });
  };

  handleConfirmPasswordChange = (event: any) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };

  handleClickShowNewPassword = () => {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword,
    }));
  };

  handleMouseDownNewPassword = (event: any) => {
    event.preventDefault();
  };

  handleMouseDownConfirmPassword = (event: any) => {
    event.preventDefault();
  };

  handleClickShowConfirmPassword = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  handleResetSubmit = () => {
    const isNewPasswordValid = this.validateNewPassword();
    const isConfirmPasswordValid = this.validateConfirmPassword();

    if (!isNewPasswordValid || !isConfirmPasswordValid) {
      return;
    } else {
      this.ResetPassword()
    }
  };

  ForgotPassword = () => {
    this.setState({ loading: true });

    const header = { "Content-Type": "application/json" };
    const data = {
      "attributes": {
        "email": this.state.email
      }
    }
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.forgotPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_forgot_password/email_verifications");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  VerifyEmail = () => {
    this.setState({ loading: true });

    const header = { "Content-Type": "application/json" };
    const data = {
      "type": "email_account",
      "attributes": {
        "email": this.state.email,
      }
    }
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/" + this.state.email);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ResetPassword = () => {
    this.setState({ loading: true });

    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('token');

    const header = { "Content-Type": "application/json" };
    const data = {
      "new_password": this.state.confirmPassword,
      "token": type
    }
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_forgot_password/passwords");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
