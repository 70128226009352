import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Paper,
    Typography,
    OutlinedInput,
    Container,
    FormHelperText,
    FormControl,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import SearchIcon from '@material-ui/icons/Search';
import { buttons, checkMark, groupBank, imageDoc, imageDownload, imageLock, textInput, viewRectangle } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Footer from "../../../components/src/Footer.web";
import TopNavBar from "../../../components/src/TopNavbar.web";
import CloseIcon from '@material-ui/icons/Close';
import Pagination from "@material-ui/lab/Pagination";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// Customizable Area End

import InvestmentOpportunitiesController, {
    Props,
} from "./InvestmentOpportunitiesController.web";

const styles: StyleRules = {
    // Customizable Area Start
    outlineStyle: {
        width: '100%',
        float: 'right',
        maxWidth: '470px',
        borderRadius: '40px',
        height: '48px',
        '&.MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(0, 105, 188)',
            },
        },
    },
    viewBtn: {
        height: '60px',
        width: '184px',
        border: '1px solid #0069BC',
        borderRadius: '12px',
        fontWeight: 700,
        color: '#0069BC',
        fontFamily: 'Roboto',
        fontSize: '20px',
        textTransform: 'none',
        gap: '8px',
    },
    allocateBtn: {
        width: '185px',
        height: '60px',
        borderRadius: '12px',
        backgroundColor: '#0069BC',
        color: '#FFFFFF',
        fontWeight: 700,
        fontSize: '20px',
        fontFamily: 'Roboto',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#0069BC",
        },
    },
    btnLockBox: {
        height: '240px',
        maxWidth: '254px',
        width: '100%',
        flexDirection: "column",
        display: "flex",
        gap: "20px",
        alignItems: "center",
        justifyContent: "center",
        cursor: 'pointer',
        backgroundImage: `url(${viewRectangle})`,
    },
    btnUnlockBox: {
        maxWidth: '254px',
        height: '240px',
        flexDirection: "column",
        display: "flex",
        gap: "20px",
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    stylePagination: {
        '& .MuiPaginationItem-textPrimary.Mui-selected': {
            backgroundColor: 'rgba(0, 105, 188, 1)'
        },
        '& .MuiPaginationItem-rounded': {
            border: '1px solid rgba(189, 189, 189, 1)',
            borderRadius: '8px',
            width: '48px',
            height: '48px'
        }
    },
    headingTxt: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: '14px',
        color: 'rgba(130, 130, 130, 1)'
    },
    valueTxt: {
        fontFamily: "Roboto",
        fontWeight: 600,
        fontSize: '20px',
        color: "rgba(0, 105, 188, 1)"
    },
    mainBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: "8px",
        alignItems: 'center',
        gap: '40px',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        '@media (max-width: 825px)': {
            flexWrap: 'wrap',
            justifyContent: "center"
        }
    },
    detailsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: "80%",
        '@media (max-width: 825px)': {
            width: '90%'
        },
        '@media (max-width: 572px)': {
            flexWrap: 'wrap',
            justifyContent: "center"
        }
    }
    // Customizable Area End
};

export class InvestmentOpportunitiesWeb extends InvestmentOpportunitiesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start

        const { classes } = this.props;
        const { kycStatus, opportunityData, totalPages, currentPage, searchValue } = this.state;


        // Customizable Area End
        return (
            // Customizable Area Start
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <TopNavBar navigation={this.props.navigation} />
                <Container style={{ display: 'flex', flexDirection: 'column', gap: '48px', padding: '40px 25px' }}>
                    <Typography style={{ fontSize: '40px', fontFamily: 'Lato', color: 'rgba(34, 34, 34, 1)', fontWeight: 800 }}>
                        Investment Opportunities
                    </Typography>
                    <Box>
                        <OutlinedInput
                            placeholder="Search fund..."
                            className={classes.outlineStyle}
                            startAdornment={<SearchIcon style={{ marginRight: '10px' }} />}
                            value={searchValue}
                            onChange={this.handleSearch}
                            onKeyDown={this.onKeyDownHandler}
                            onBlur={() => this.getInvestmentOpportunitiesDetails(this.state.investmentType)}
                        />
                    </Box>
                    {opportunityData.length > 0 ? opportunityData.map((x: any, index: any) => (
                        <Box key={index} className={classes.mainBox}>
                            <Box style={{ width: '20%', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={x.attributes.company.data[0]?.attributes.image} onClick={() => window.location.href = `/OpportunitiesDetailsWeb/${x.id}`} style={{ maxHeight: '100px', maxWidth: '200px', cursor: 'pointer',height:'auto', width:'auto' }} />
                            </Box>
                            <Box className={classes.detailsBox}>
                                <Box style={{ width: '75%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column',padding:'25px 0px' }}>
                                    <Box style={{ marginBottom: '20px' }}>
                                        <Typography style={{ fontFamily: "Lato", fontWeight: 700, fontSize: '24px' }}>{x.attributes.company.data[0]?.attributes.company_name}</Typography>
                                    </Box>
                                    <Box style={{ marginBottom: '50px' }}>
                                        <Typography style={{ fontFamily: "Roboto", fontWeight: 400, fontSize: '16px',  color: "rgba(130, 130, 130, 1)" }}>{x.attributes.company.data[0]?.attributes.about}</Typography>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '16px' }}>
                                        <Box>
                                            <Typography className={classes.headingTxt} >Minimum Investment</Typography>
                                            <Typography className={classes.valueTxt}>${this.numberWithComma(x.attributes.minimum_investment)}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={classes.headingTxt}>Allocation</Typography>
                                            <Typography className={classes.valueTxt}>${this.numberWithComma(x.attributes.allocation)}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={classes.headingTxt}>Round</Typography>
                                            <Typography className={classes.valueTxt}>{x.attributes.round}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={classes.headingTxt}>Estimated Fees</Typography>
                                            <Typography className={classes.valueTxt}>{x.attributes.estimated_fee}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {(kycStatus !== "verified") ?
                                    <Box className={classes.btnLockBox} style={{ paddingRight: '16px' }} onClick={this.redirectToInvest} data-test-id={"investBtn" + index}>
                                        <img src={imageLock} style={{ width: '50px', height: '50px' }} />
                                        <Typography style={{ fontFamily: "Roboto", fontSize: '14px', maxWidth: '210px', textAlign: 'center' }}>Complete the declaration to view full details</Typography>
                                        <Typography style={{ fontFamily: "Roboto", fontWeight: 700, fontSize: '18px', color: "rgba(0, 105, 188, 1)" }}>Complete Declaration</Typography>
                                    </Box> :
                                    <Box className={classes.btnUnlockBox} >
                                        <Button className={classes.viewBtn} onClick={() => window.location.href = `/OpportunitiesDetailsWeb/${x.id}`}> View Details </Button>
                                        <Button className={classes.allocateBtn} onClick={() =>
                                            window.location.href = `/OpportunitiesDetailsWeb/${x.id}`
                                        }>Allocate Now</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    )) :
                        this.state.showLoading && <Box className={classes.mainBox} style={{ display: 'flex', padding: "30px", justifyContent:'center', height: '100px', fontSize: '25px',width: 'auto', fontFamily: 'Lato' }}>
                            There are no opportunities found with this name
                        </Box>
                    }
                    {opportunityData.length > 0 &&
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={this.handlePageChange}
                                shape="rounded"
                                size="large"
                                color="primary"
                                className={classes.stylePagination}
                            />
                        </Box>
                    }
                </Container>

                <Footer navigation={this.props.navigation}/>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(InvestmentOpportunitiesWeb);
// Customizable Area End
