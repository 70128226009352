import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    afterIntroductory: boolean;
    introductoryProfile: boolean;
    afterAdditional: boolean;
    receiveRequest: boolean;
    websiteLink: string;
    startupName: string;
    personName: string;
    profileType: string;
    currentValuation: string;
    offerValuation: string;
    qualityShare: string;
    lastThreeYearRevenue: string;
    additionalInfo: string;
    declaration: boolean;
    esopLetter: any;
    otherFile: any;
    shareCertificate: any;
    investmentAgreement: any;
    firstPageError: any;
    secondPageError: any;
    thirdPageError: any;
    PreviousYearRevenue: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SecondariesInvestmentLiquidityController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    submitSecondariesInvestApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            loading: false,
            afterIntroductory: false,
            introductoryProfile: true,
            afterAdditional: false,
            profileType: '',
            personName: '',
            startupName: '',
            websiteLink: '',
            currentValuation: '',
            offerValuation: '',
            qualityShare: '',
            lastThreeYearRevenue: '',
            additionalInfo: '',
            declaration: false,
            receiveRequest: false,
            esopLetter: null,
            otherFile: null,
            shareCertificate: null,
            investmentAgreement: null,
            firstPageError: {
                profileType: '',
                personName: '',
                startupName: '',
                websiteLink: ''
            },
            secondPageError: {
                currentValuation: '',
                offerValuation: '',
                qualityShare: '',
                lastThreeYearRevenue: '',
                additionalInfo: '',
                declaration: '',
                PreviousYearRevenue: '',
            },
            thirdPageError: {
                otherFile: '',
                esopLetter: '',
                shareCertificate: '',
                investmentAgreement: ''
            },
            PreviousYearRevenue: ''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.submitSecondariesInvestApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        receiveRequest: true,
                        afterAdditional: false,
                    })
                    setTimeout(() => {
                        this.setState({
                            receiveRequest: false
                        }, () => {
                            this.props.navigation.navigate("SecondariesInvestmentWeb")
                        })
                    }, 3000);
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    gotoAdditional = () => {
        const isProfileValid = this.validateProfile();
        const isPersonNameValid = this.validatePersonName();
        const isStartUpValid = this.validateStartUpName();
        const isWebLinkValid = this.validateWebLink();

        if (!isProfileValid || !isPersonNameValid || !isStartUpValid || !isWebLinkValid) {
            return;
        } else {
            this.setState({
                afterIntroductory: true,
                introductoryProfile: false,
            })
        }
    }

    validateFirstPage = () => {
        const { profileType, personName, startupName, websiteLink } = this.state;

        if (!profileType) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    profileType: 'Profile Type is required'
                },
            }));
            return false;
        } else if (profileType.length > 30) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    profileType: 'Profile Type should not exceed 30 characters'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    profileType: ''
                },
            }));
        }

        if (!personName) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    personName: 'Person Name is required'
                },
            }));
            return false;
        } else if (personName.length > 30) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    personName: 'Person Name should not exceed 30 characters'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    personName: ''
                },
            }));
        }

        if (!startupName) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    startupName: 'Startup Name is required'
                },
            }));
            return false;
        } else if (startupName.length > 30) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    startupName: 'Startup Name should not exceed 30 characters'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    startupName: ''
                },
            }));
        }

        const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9._%+-]*)*$/;

        if (!websiteLink) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    websiteLink: 'Website Link is required'
                },
            }));
            return false;
        } else if (websiteLink && !websiteRegex.test(websiteLink)) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    websiteLink: 'Invalid Website Link format'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    websiteLink: ''
                },
            }));
        }

        return true;
    };


    validateSecondPage = () => {
        const { currentValuation, offerValuation, qualityShare, declaration, lastThreeYearRevenue, additionalInfo } = this.state;

        if (!currentValuation) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    currentValuation: 'Current Valuation is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    currentValuation: ''
                },
            }));
        }



        if (!offerValuation) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    offerValuation: 'Offer Valuation is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    offerValuation: ''
                },
            }));
        }

        if (!qualityShare) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    qualityShare: 'Quality Share is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    qualityShare: ''
                },
            }));
        }


        if (!lastThreeYearRevenue) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    lastThreeYearRevenue: 'Last three year revenue is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    lastThreeYearRevenue: ''
                },
            }));
        }

        if (!additionalInfo) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    additionalInfo: 'Additional information is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    additionalInfo: ''
                },
            }));
        }

        if (!declaration) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    declaration: 'Declaration is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    declaration: ''
                },
            }));
        }

        return true;
    };

    validateThirdPage = () => {
        const { esopLetter, shareCertificate, investmentAgreement, otherFile } = this.state;

        const isValidInvestmentAgreement = !!investmentAgreement;
        const isValidShareCertificate = !!shareCertificate;
        const isValidEsopLetter = !!esopLetter;
        const isValidOtherFile = !!otherFile;
    
        return isValidEsopLetter || isValidShareCertificate || isValidInvestmentAgreement || isValidOtherFile;
    };

    handleProfileChange = (event: any) => {
        this.setState((prevState) => ({
            profileType: event.target.value,
            firstPageError: {
                ...prevState.firstPageError,
                profileType: ''
            }
        }));

    }

    handleNameChange = (event: any) => {
        this.setState((prevState) => ({
            personName: event.target.value,
            firstPageError: {
                ...prevState.firstPageError,
                personName: ''
            }
         }));
    }

    handleStartUpNameChange = (event: any) => {
        this.setState((prevState) => ({
            startupName: event.target.value,
            firstPageError: {
                ...prevState.firstPageError,
                startupName: ''
            }
        }));
    }

    handleWebLinkChange = (event: any) => {
        this.setState((prevState) => ({
            websiteLink: event.target.value,
            firstPageError: {
                ...prevState.firstPageError,
                webssiteLink: ''
            }
        }));
    }

    handleCurrentValueChange = (event: any) => {
       this.setState((prevState) => ({
            currentValuation: event.target.value,
            secondPageError: {
                ...prevState.secondPageError,
                currentValuation: ''
            }
        }));
    }

    handleOfferValueChange = (event: any) => {
       this.setState((prevState) => ({
            offerValuation: event.target.value,
            secondPageError: {
                ...prevState.secondPageError,
                offerValuation: ''
            }
        }));
    }

    handleQualityShareChange = (event: any) => {
       this.setState((prevState) => ({
            qualityShare: event.target.value,
            secondPageError: {
                ...prevState.secondPageError,
                qualityShare: ''
            }
        }));
    }

    handleThreeYearRevenueChange = (event: any) => {
       this.setState((prevState) => ({
            lastThreeYearRevenue: event.target.value,
            secondPageError: {
                ...prevState.secondPageError,
                lastThreeYearRevenue: ''
            }
        }));
    }

    handlePreviousYearRevenueChange = (event: any) => {
        this.setState((prevState) => ({
            PreviousYearRevenue: event.target.value,
            secondPageError: {
                ...prevState.secondPageError,
                PreviousYearRevenue: ''
            }
        }));
    }

    handleAdditionalInfoChange = (event: any) => {
       this.setState((prevState) => ({
            additionalInfo: event.target.value,
            secondPageError: {
                ...prevState.secondPageError,
                additionalInfo: ''
            }
        }));
    }

    handleDeclarationChange = () => {
       this.setState((prevState) => ({
            declaration: !this.state.declaration,
            secondPageError: {
                ...prevState.secondPageError,
                declaration: ''
            }
        }));
    }

    gotoDocument = () => {
        const isCurrentValue = this.validateCurrentValue();
        const isOfferValue = this.validateOfferValue();
        const isQualityShare = this.validateQualityShare();
        const isThreeYearRevenue = this.validateThreeYearRevenue();
        const isAdditionalInfo = this.validateAdditionalInfo();
        const isDeclaration = this.validateDeclaration();
        const isPreviousYearRevenue = this.validatePreviousYearRevenue()
        if (!isCurrentValue || !isOfferValue || !isQualityShare || !isThreeYearRevenue || !isAdditionalInfo || !isDeclaration || !isPreviousYearRevenue) {
            return;
        }else {
            this.setState({
                afterAdditional: true,
                afterIntroductory: false,
            })
        }
    }

    uploadESOPFile = (event: any) => {
        const file = event.target.files[0];
        const validFileTypeESO = ['image/jpeg', 'image/jpg', 'application/pdf'];
        if (file && validFileTypeESO.includes(file.type)) {
            this.setState((prevState) => ({
                esopLetter: file,
                thirdPageError: {
                    ...prevState.thirdPageError,
                    esopLetter: ''
                }
            }))
        } else {
            this.setState((prevState) => ({
                esopLetter: null,
                thirdPageError: {
                    ...prevState.thirdPageError,
                    esopLetter: 'Please upload a file in pdf, jpg, jpeg format only.',
                },
            }));
        }
    }

    uploadInvestmentAgreementFile = (event: any) => {
        const file = event.target.files[0];
        const validFileTypes = ['application/pdf', 'image/jpeg', 'image/jpg'];
        if (file && validFileTypes.includes(file.type)) {
        this.setState((prevState) => ({
            investmentAgreement: file,
            thirdPageError: {
                ...prevState.thirdPageError,
                investmentAgreement: ''
            }
        }))
        } else {
            this.setState((prevState) => ({
                investmentAgreement: null,
                thirdPageError: {
                    ...prevState.thirdPageError,
                    investmentAgreement: 'Please upload a file in pdf, jpg, jpeg format only.',
                },
            }));
        }
    }

    uploadShareCertificateFile = (event: any) => {
        const file = event.target.files[0];
        const validFileTypeShare = ['application/pdf', 'image/jpg', 'image/jpeg'];
        if (file && validFileTypeShare.includes(file.type)) {
        this.setState((prevState) => ({
            shareCertificate: file,
            thirdPageError: {
                ...prevState.thirdPageError,
                shareCertificate: ''
            }
        }))
        } else {
            this.setState((prevState) => ({
                shareCertificate: null,
                thirdPageError: {
                    ...prevState.thirdPageError,
                    shareCertificate: 'Please upload a file in pdf, jpg, jpeg format only.',
                },
            }));
        }
    }

    uploadOtherFile = (event: any) => {
        const file = event.target.files[0];
        const validFileType = ['application/pdf', 'image/jpg', 'image/jpeg'];
        if (file && validFileType.includes(file.type)) {
        this.setState((prevState) => ({
            otherFile: file,
            thirdPageError: {
                ...prevState.thirdPageError,
                otherFile: ''
            }
        }))
        } else {
            this.setState((prevState) => ({
                otherFile: null,
                thirdPageError: {
                    ...prevState.thirdPageError,
                    otherFile: 'Please upload a file in pdf, jpg, jpeg format only.',
                },
            }));
        }
    }

    handleRemoveInvestmentAgreement = (event: any) => {
        event.stopPropagation();
        event.preventDefault()
        this.setState({ investmentAgreement: null });
    }

    handleRemoveESOPDoc = (event: any) => {
        event.stopPropagation();
        event.preventDefault()
        this.setState({ esopLetter: null });
    }

    handleRemoveShareCertificate = (event: any) => {
        event.stopPropagation();
        event.preventDefault()
        this.setState({ shareCertificate: null });
    }

    handleRemoveOtherFile = (event: any) => {
        event.stopPropagation();
        event.preventDefault()
        this.setState((prevState) => ({
            otherFile: null,
            thirdPageError: {
                ...prevState.thirdPageError,
                otherFile: '',
            },
        }));
    }

    navigateToSettings = async () => {
        const goToContactUs: Message = new Message(getName(MessageEnum.NavigationMessage));
        goToContactUs.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
        goToContactUs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(goToContactUs);
        await setStorageData("contactUs", 'showContactUs');
    }

    handleSubmit = () => {
        if (this.validateThirdPage()) {
            this.submitWaitingList();
        }
    }

    submitWaitingList = async () => {
        this.setState({ loading: true });
        const { profileType, websiteLink, startupName, personName, currentValuation, shareCertificate, investmentAgreement, offerValuation, qualityShare, lastThreeYearRevenue, additionalInfo, declaration, esopLetter, otherFile, PreviousYearRevenue } = this.state;

        const updatedData = {
            currentValuation: currentValuation.toString().replace(/[$,]/g, ''),
            offerValuation: offerValuation.toString().replace(/[$,]/g, ''),
            lastThreeYearRevenue: lastThreeYearRevenue.toString().replace(/[$,]/g, ''),
            PreviousYearRevenue: PreviousYearRevenue.toString().replace(/[$,]/g, ''),

        }
        const formData = new FormData();
        formData.append('data[attributes][profile_type]', profileType);
        formData.append('data[attributes][name]', personName);
        formData.append('data[attributes][company_name]', startupName);
        formData.append('data[attributes][website_link]', websiteLink);
        formData.append('data[attributes][current_valuation]', updatedData.currentValuation);
        formData.append('data[attributes][offered_valuation]', updatedData.offerValuation);
        formData.append('data[attributes][quality_of_the_shares]', qualityShare);
        formData.append('data[attributes][last_three_year_revenue]', updatedData.lastThreeYearRevenue);
        formData.append('data[attributes][previous_year_revenue]', updatedData.PreviousYearRevenue);
        formData.append('data[attributes][additional_information]', additionalInfo);
        formData.append('data[attributes][declaration]', declaration.toString());
        otherFile && formData.append('data[attributes][other][]', otherFile);
        esopLetter && formData.append('data[attributes][esop_letter][]', esopLetter);
        shareCertificate && formData.append('data[attributes][share_certificate][]', shareCertificate);
        investmentAgreement && formData.append('data[attributes][investment_agreement][]', investmentAgreement);

        const header = { token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.submitSecondariesInvestApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_proposalgeneration2/secondary_profiles");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    validateProfile = () => {
        const { profileType } = this.state;
        if (!profileType) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    profileType: 'Profile Type is required'
                },
            }));
            return false;
        }

        if (profileType.length > 30) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    profileType: 'Profile Type should not exceed 30 characters'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            firstPageError: {
                ...prevState.firstPageError,
                profileType: ''
            },
        }));
        return true;
    };

    validatePersonName = () => {
        const { personName } = this.state;
        if (!personName) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    personName: 'Person Name is required'
                },
            }));
            return false;
        }

        if (personName.length > 30) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    personName: 'Person Name should not exceed 30 characters'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            firstPageError: {
                ...prevState.firstPageError,
                personName: ''
            },
        }));
        return true;
    };

    validateStartUpName = () => {
        const { startupName } = this.state;
        if (!startupName) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    startupName: 'Startup Name is required'
                },
            }));
            return false;
        }

        if (startupName.length > 30) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    startupName: 'Startup Name should not exceed 30 characters'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            firstPageError: {
                ...prevState.firstPageError,
                startupName: ''
            },
        }));
        return true;
    };

    validateWebLink = () => {
        const { websiteLink } = this.state;
        const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9._%+-]*)*$/;

        if (!websiteLink) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    websiteLink: 'Website Link is required'
                },
            }));
            return false;
        }
        if (websiteLink && !websiteRegex.test(websiteLink)) {
            this.setState((prevState) => ({
                firstPageError: {
                    ...prevState.firstPageError,
                    websiteLink: 'Invalid Website Link format'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            firstPageError: {
                ...prevState.firstPageError,
                websiteLink: ''
            },
        }));
        return true;
    };

    validateCurrentValue = () => {
        const { currentValuation } = this.state;
        if (!currentValuation) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    currentValuation: 'Current Valuation is required'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            secondPageError: {
                ...prevState.secondPageError,
                currentValuation: ''
            },
        }));
        return true
    };

    validateOfferValue = () => {
        const { offerValuation } = this.state;
        if (!offerValuation) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    offerValuation: 'Offer Valuation is required'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            secondPageError: {
                ...prevState.secondPageError,
                offerValuation: ''
            },
        }));
        return true
    }

    validateQualityShare = () => {
        const { qualityShare } = this.state
        if (!qualityShare) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    qualityShare: 'Quality Share is required'
                },
            }));
            return false;
        }
        this.setState((prevState) => ({
            secondPageError: {
                ...prevState.secondPageError,
                qualityShare: ''
            },
        }));
        return true
    }

    validateThreeYearRevenue = () => {
        const { lastThreeYearRevenue } = this.state
        if (!lastThreeYearRevenue) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    lastThreeYearRevenue: 'Last three year revenue is required'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            secondPageError: {
                ...prevState.secondPageError,
                lastThreeYearRevenue: ''
            },
        }));
        return true
    }

    validatePreviousYearRevenue = () => {
        const { PreviousYearRevenue } = this.state
        if (!PreviousYearRevenue) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    PreviousYearRevenue: 'Previous year revenue is required'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            secondPageError: {
                ...prevState.secondPageError,
                PreviousYearRevenue: ''
            },
        }));
        return true
    }

    validateAdditionalInfo = () => {
        const { additionalInfo } = this.state
        if (!additionalInfo) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    additionalInfo: 'Additional information is required'
                },
            }));
            return false;
        }

        this.setState((prevState) => ({
            secondPageError: {
                ...prevState.secondPageError,
                additionalInfo: ''
            },
        }));
        return true
    }

    validateDeclaration = () => {
        const { declaration } = this.state
        if (!declaration) {
            this.setState((prevState) => ({
                secondPageError: {
                    ...prevState.secondPageError,
                    declaration: 'Declaration is required'
                },
            }));
            return false;
        }
        this.setState((prevState) => ({
            secondPageError: {
                ...prevState.secondPageError,
                declaration: ''
            },
        }));
        return true
    }
    // Customizable Area End
}
