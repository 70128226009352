import React from "react";

// Customizable Area Start
import {
    Paper,
    Container,
    Box,
    Typography,
    Button,
    Table,
    TableHead,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    LinearProgress,
    DialogContent,
    DialogTitle,
    Dialog,
    Divider,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import Footer from "../../../components/src/Footer.web";
import CloseIcon from '@material-ui/icons/Close';
import TopNavBar from "../../../components/src/TopNavbar.web";
import { Chart } from "react-google-charts";
import Loader from "../../../components/src/Loader.web";
import { allocationLogo, certificateAccessLogo, chartImage, documentLogo, fundinvestment, hand, latestinvestment, paymentLogo, pdfDocument, portfoliovalue, released, secondaries, startupinvestment, totalinvestment,defaultImage, jpgImage, pngImage, zipImage, jpegImage  } from "./assets";
// Customizable Area End

import PortfolioManagementController, {
    Props,
} from "./PortfolioManagementController.web";

const styles: StyleRules = {
    // Customizable Area Start
    portfolioList: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        lineHeight: '24px'

    },
    descriptionText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(111, 111, 112, 1)'
    },
    chartBackground: {
        backgroundColor: 'rgba(179, 215, 243, 1)',
        borderRadius: '16px',
        height: '312.26px',
        // minWidth: '570px',
        fontSize: '16px',
        display: 'flex',
    },
    browseOppurtunitiesBtn: {
        borderRadius: '12px',
        marginLeft: '42px',
        padding: '20px',
        height: '60px',
        backgroundColor: 'rgba(0, 105, 188, 1)',
        color: 'rgba(255, 255, 255, 1)',
        fontFamily: 'Roboto',
        fontSize: '17px',
        fontWeight: 700,
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: "rgba(0, 105, 188, 1)",
        },
    },
    headingTitle: {
        fontFamily: 'Roboto', fontSize: '24px', fontWeight: 700
    },
    boxStyle: {
        border: '1px solid rgba(217, 217, 217, 1)',
        borderRadius: '12px',
        height: '228px',
        width: '370px',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
        gap: '10px',
    },
    customBtn: {
        border: '1px solid #0069BC',
        borderRadius: '12px',
        height: '173px',
        width: '265px',
        gap: '16px',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 0px 0px 19px',
        fontWeight: 700,
        color: '#0069BC',
        fontFamily: 'Roboto',
        fontSize: '20px',
        textTransform: 'none',
        background: 'rgba(0, 105, 188, 0.05)',
        '@media (min-width: 1200px)': {
            width: '240px',
        }
    },
    tableHeader: {
        color: 'rgba(0, 105, 188, 1)',
        fontSize: '18px',
        fontWeight: 700,
        fontFamily: 'Roboto'
    },
    tableCellBorder: {
        borderBottom: 'unset'
    },
    tableRowBorder: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    tableBody: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 600
    },
    viewBtn: {
        textTransform: "none",
        backgroundColor: ' rgba(255, 255, 255, 1)',
        height: "40px",
        width: "100px",
        color: "rgba(0, 105, 188, 1)",
        border: '1px solid rgba(0, 105, 188, 1)',
        borderRadius: "8px",
        alignSelf: 'center',
        display: 'flex',
        fontWeight: 700,
        fontSize: "16px",
        fontFamily: 'Roboto',
        gap: '8px',
        float: 'inline-end',
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
        },
    },
    headingTxt: {
        fontWeight: 600,
        color: 'rgba(0, 105, 188, 1)',
    },
    profileDoc: {
        width: '214px',
        height: '123px',
        border: '1px solid rgba(189, 189, 189, 1)',
        borderRadius: '12px',
        gap: '8px',
        padding: '24px',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'start'
    },
    chartText: {
        color: 'rgba(111, 111, 112, 1)',
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    documentView: {
        width: '100%',
        height: '70px',
        borderRadius: '12px',
        display: 'flex',
        padding: '24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid rgba(189, 189, 189, 1)'
    },
    progeressStyle: {
        backgroundColor: 'white',
        height: '12px',
        borderRadius: '2px',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'rgba(0, 105, 188, 0.1)'
        }
    },
    imgWidth:{
        width: '48px',
        heigth: '48px',
    }
    // Customizable Area End
};

export class PortfolioManagementWeb extends PortfolioManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    ViewCompanyDetails = () => {

        const { introductoryProfileOpen, selectedViewDetails } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                open={introductoryProfileOpen}
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        width: 'auto',
                        padding: '32px',
                        display: 'flex',
                        maxHeight: '88vh',
                        gap: '32px',
                        scrollbarWidth: 'none', msOverflowStyle: 'none',
                    }
                }}>
                <DialogTitle style={{ padding: '0px', position: 'relative' }}>
                    <Typography style={{ color: '#222222', fontSize: '32px', fontWeight: 800, textAlign: 'center', fontFamily: 'Lato' }}>Investment Status</Typography>
                    <CloseIcon style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }} onClick={this.closeProfile} />
                </DialogTitle>
                <DialogContent style={{ gap: '20px', height: '100%', minHeight: '591px', minWidth: '506px', display: 'flex', flexDirection: 'column', scrollbarWidth: 'none', msOverflowStyle: 'none', padding: '0px',marginBottom:'20px' }}>
                    <Box style={{ display: 'flex'}}>
                        <Box>
                            <img src={selectedViewDetails?.attributes?.opportunity.data.attributes.company.data[0].attributes.image} style={{ maxHeight: '80px', maxWidth: '80px', height: 'auto', width: 'auto' }} />
                        </Box>
                        <Box style={{ display: 'flex', width: '402px', height: '55px', paddingLeft:'10px' }}>
                            <Box style={{ width: '300px' }}>
                                <Typography className={classes.headingTitle} >{selectedViewDetails?.attributes?.opportunity.data.attributes.company.data[0].attributes.company_name}</Typography>
                                <Typography className={classes.descriptionText} >{this.showInvestType(selectedViewDetails?.attributes?.opportunity.data.attributes.company.data[0].attributes.company_type)}</Typography>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'end', width: '102px' }}>
                                <Typography style={{ fontFamily: 'Roboto', display: 'flex', fontSize: '24px', fontWeight: 700, color: 'rgba(0, 105, 188, 1)' }}>${this.numberWithCommas(selectedViewDetails?.attributes?.amount)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box style={{ width: '506px', height: 'auto', display: 'flex', gap: '12px', flexDirection: 'column' }}>
                        <Typography style={{ fontFamily: 'Roboto', fontSize: '20px', fontWeight: 700 }} >About Company</Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: 'rgba(111, 111, 112, 1)', wordWrap: 'break-word' }}>
                            {selectedViewDetails?.attributes?.opportunity.data.attributes.company.data[0].attributes.about}
                        </Typography>
                    </Box>

                    <Box style={{ width: '506px', display: 'flex', gap: '30px' }}>

                        <Box className={classes.profileDoc} >
                            <img src={allocationLogo} className={classes.imgWidth}/>
                            <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '14px', fontWeight: 400, fontStyle: 'Roboto' }}>Allocation</Typography>
                            <Typography style={{ fontSize: '20px', fontWeight: 600, fontStyle: 'Roboto', textTransform: 'capitalize' }}>{selectedViewDetails?.attributes?.allocation}</Typography>
                        </Box>

                        <Box className={classes.profileDoc} >
                            <img className={classes.imgWidth} src={documentLogo} />
                            <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '14px', fontWeight: 400, fontStyle: 'Roboto' }}>Document signing</Typography>
                            <Typography style={{ fontSize: '20px', fontWeight: 600, fontStyle: 'Roboto', textTransform: 'capitalize' }}>{selectedViewDetails?.attributes?.document_sign}</Typography>
                        </Box>
                    </Box>

                    <Box style={{ width: '506px', display: 'flex', gap: '30px' }}>

                        <Box className={classes.profileDoc}>
                            <img src={paymentLogo} className={classes.imgWidth}/>
                            <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '14px', fontWeight: 400, fontStyle: 'Roboto' }}>Payment Confirmation</Typography>
                            <Typography style={{ fontSize: '20px', fontWeight: 600, fontStyle: 'Roboto', textTransform: 'capitalize' }}>{selectedViewDetails?.attributes?.payment_confirmation}</Typography>
                        </Box>

                        <Box className={classes.profileDoc} >
                            <img src={certificateAccessLogo} className={classes.imgWidth}/>
                            <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '14px', fontWeight: 400, fontStyle: 'Roboto' }}>Share certificate access</Typography>
                            <Typography style={{ fontSize: '20px', fontWeight: 600, textTransform: 'capitalize', fontStyle: 'Roboto' }}>{selectedViewDetails?.attributes?.certificate_access}</Typography>
                        </Box>
                    </Box>

                    <Box style={{ width: '506px', height: '269px', display: 'flex', gap: '24px', flexDirection: 'column' }}>
                        <Box>
                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '20px' }}>Documents</Typography>
                            <Box style={{ paddingTop: '10px', paddingBottom: '18px' }}>
                                <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '14px', fontWeight: 400, fontStyle: 'Roboto' }}>Note: Signed agreements have been sent to your email ID via DocuSign and can be downloaded from your mailbox</Typography>
                            </Box>
                        </Box>
                        {selectedViewDetails?.attributes?.opportunity.data.attributes.documents.data.map((documents: any, index: number) => (
                            <Box style={{ display: 'flex', gap: '30px', height: '222px', width: '506px' }}>
                                <Box className={classes.documentView}>
                                    <Box style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                        <img src={this.previewImageHandler(documents.attributes.document?.file_type)} style={{ maxHeight: '45px', maxWidth: '45px', width: 'auto', height: 'auto' }} />
                                        <Typography style={{ fontFamily: 'Roboto', fontSize: '20px', fontWeight: 700 }}>Document {index+1}.pdf</Typography>
                                    </Box>
                                    <Typography
                                        data-test-id={'viewDocumentBtn' + index}
                                        onClick={() => window.open(documents.attributes.document?.url, '_blank')}
                                        style={{ fontFamily: 'Roboto', fontSize: '18px', textDecoration: 'underline', fontWeight: 500, color: 'rgba(0, 105, 188, 1)', cursor: 'pointer' }}
                                    >
                                        View document
                                    </Typography>
                                </Box>
                            </Box>))}
                    </Box>

                </DialogContent>
            </Dialog>
        )
    }

    showInvestType = (data: 'direct_startup_investment' | 'fund_investment' | 'secondaries'): string => {
        const company_type: { [key: string]: string } = {
            direct_startup_investment: "Startup investment",
            fund_investment: "Fund investment",
            secondaries: "Secondaries",
        };
        return company_type[data];
    };

    previewImageHandler = (fileContent: string) => {
        const extractedFileType = fileContent?.split('&')[0];
        let previewImg = defaultImage;
        if (fileContent) {
            if (extractedFileType.includes('jpg')) {
                previewImg = jpgImage;
            } else if (extractedFileType.includes('jpeg')) {
                previewImg = jpegImage;
            } else if (extractedFileType.includes('png')) {
                previewImg = pngImage;
            } else if (extractedFileType.includes('pdf')) {
                previewImg = pdfDocument;
            } else if (extractedFileType.includes('zip')) {
                previewImg = zipImage;
            }
        }
        return previewImg
    }
    

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { portfolioDetails, portfolioInvestmentList, userName , loading} = this.state;
        const { classes } = this.props;

        const chartData = [
            ["name", "value"],
            ...(portfolioDetails && portfolioDetails.sector_percentages_last_one_year ? portfolioDetails.sector_percentages_last_one_year.map((item: any) => [item.name, item.value]) : [])
        ];

        const chartcolor = (portfolioDetails && portfolioDetails.sector_percentages_last_one_year ? portfolioDetails.sector_percentages_last_one_year.map((item: any) => item.color) : [])

        // Customizable Area End

        return (
            // Customizable Area Start
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <TopNavBar navigation={this.props.navigation} />
                {this.ViewCompanyDetails()}
                {loading &&
                    <Container style={{ height: '60vh' }}>
                        <Loader loading={loading} />
                    </Container>
                }
                {!loading && portfolioInvestmentList?.length > 0  &&
                    <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '48px', padding: '40px 20px' }}>
                        <Box style={{ display: 'flex', gap: '10', flexDirection: 'column', alignSelf: 'start' }}>
                            <Typography style={{ fontSize: '40px', fontFamily: 'Lato', color: 'rgba(34, 34, 34, 1)', fontWeight: 400 }}>
                                Hey <span style={{ color: 'rgba(0, 134, 240, 1)', fontWeight: 600, textTransform: 'capitalize' }}>{userName} ,<img style={{ height: '50px', width: '50px' }} src={hand} /></span>
                            </Typography>
                            <Typography style={{ color: 'rgba(0, 105, 188, 1)', fontWeight: 400, fontSize: '16px', fontFamily: 'Roboto', top: '208px' }}>Monitor your investment portfolio</Typography>
                        </Box>

                        <Box style={{ display: 'flex', gap: '25px', flexWrap: 'wrap', padding: '0px, 0px, 24px, 0px', }}>
                            <Box className={classes.customBtn} >
                                <Box>
                                    <img src={portfoliovalue} style={{ height: '48px', width: '48px' }} />
                                </Box>
                                <Box style={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
                                    <Typography style={{ color: 'rgba(34, 34, 34, 1)', fontSize: '32px', fontWeight: 600, fontStyle: 'Roboto' }}>$ {this.numberWithCommas(portfolioDetails?.portfolio_amount)}</Typography>
                                    <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '16px', fontWeight: 400, fontStyle: 'Roboto' }}>Portfolio Value</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.customBtn} >
                                <Box>
                                    <img src={totalinvestment} style={{ height: '48px', width: '48px' }}/>
                                </Box>
                                <Box >
                                    <Typography style={{ color: 'rgba(34, 34, 34, 1)', fontSize: '32px', fontWeight: 600, fontStyle: 'Roboto' }}>{portfolioDetails?.total_investment}</Typography>
                                    <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '16px', fontWeight: 400, fontStyle: 'Roboto' }}>Total Investments</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.customBtn} >
                                <Box>
                                    <img style={{ height: '48px', width: '48px' }} src={released} />
                                </Box>
                                <Box >
                                    <Typography style={{ color: 'rgba(34, 34, 34, 1)', fontSize: '32px', fontWeight: 600, fontStyle: 'Roboto' }}>$ {this.numberWithCommas(portfolioDetails?.realised_amount)}</Typography>
                                    <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '16px', fontWeight: 400, fontStyle: 'Roboto' }}>Realised</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.customBtn}>
                                <Box>
                                    <img src={latestinvestment} style={{ height: '48px', width: '48px' }} />
                                </Box>
                                <Box >
                                    <Typography style={{ color: 'rgba(34, 34, 34, 1)', fontSize: '32px', fontWeight: 600, fontStyle: 'Roboto' }}>
                                        {this.formatDate(
                                            portfolioDetails?.last_investment?.updated_at
                                        )}
                                    </Typography>
                                    <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontSize: '16px', fontWeight: 400, fontStyle: 'Roboto' }}>Latest Investment</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Divider style={{ color: 'rgba(189, 189, 189, 1)' }} />

                        <Box style={{ display: 'flex', justifyContent: 'space-evenly', gap: '30px', flexWrap: 'wrap', width: '100%' }}>
                            <Box style={{ border: '1px solid rgba(0, 105, 188, 1)', borderRadius: '16px', maxWidth: '530px', width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'column', height: '363px' }}>
                                <Box style={{ display: 'flex', gap: '12px', flexDirection: 'column', minWidth: '266px', height: '55px', padding: '17px' }}>
                                    <Typography style={{ fontFamily: 'Lato', fontWeight: 800, fontSize: '20px', }}>Sector Overview</Typography>
                                </Box>
                                <Chart
                                    chartType="PieChart"
                                    data={chartData}
                                    width={"100%"}
                                    height={"241px"}
                                    options={{ colors: chartcolor }}
                                />
                            </Box>

                            <Box style={{ border: '1px solid rgba(0, 105, 188, 1)', borderRadius: '16px', maxWidth: '570px', width: '100%', display: 'flex', flexDirection: 'column', height: '363px', padding: '5px' }}>
                                <Box style={{ display: 'flex', gap: '12px', flexDirection: 'column', minWidth: '266px', height: '55px', padding: '17px' }}>
                                    <Typography style={{ fontFamily: 'Lato', fontWeight: 800, fontSize: '20px', }}>Industry Overview</Typography>
                                    <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: 'rgba(111, 111, 112, 1)' }}>by % of investments in past 3 months</Typography>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '24px', height: '228px', padding: '17px' }}>
                                    {portfolioDetails?.sector_percentages_last_3_months?.map((item: any) => (
                                        <Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography style={{ fontWeight: 600, fontSize: '16px', fontFamily: 'Roboto' }}>{item.name}</Typography>
                                                <Typography style={{ fontWeight: 600, fontSize: '16px', fontFamily: 'Roboto', }}>{item.value}%</Typography>
                                            </Box>
                                            <Box>
                                                <LinearProgress variant="determinate" value={item.value} className={classes.progeressStyle} />
                                            </Box>
                                        </Box>))}
                                </Box>
                            </Box>
                        </Box>

                        <Divider style={{ color: 'rgba(189, 189, 189, 1)' }} />

                        <Box style={{ width: '100%', height: '100%', minHeight: '474px', borderRadius: '16px', border: '1px solid rgba(189, 189, 189, 1)', }}>
                            <Box style={{ padding: '25px' }}>
                                <Typography style={{ fontFamily: 'Lato', fontWeight: 800, fontSize: '20px' }}>Investments</Typography>
                            </Box>
                            <Box>
                                <TableContainer >
                                    <Table aria-label="simple table" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableHeader}>Company</TableCell>
                                                <TableCell className={classes.tableHeader} style={{ textAlign: "center", width: "250px" }}>Status</TableCell>
                                                <TableCell className={classes.tableHeader}>Invested Date</TableCell>
                                                <TableCell className={classes.tableHeader}>Invested Amount</TableCell>
                                                <TableCell className={classes.tableHeader}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {portfolioInvestmentList?.map((item: any, index: number) => (
                                                <TableRow className={classes.tableRowBorder}>
                                                    <TableCell style={{ display: 'flex', alignItems: 'center', gap: '12px' }} className={classes.tableCellBorder}>
                                                        <img src={item.attributes.opportunity.data.attributes.company.data[0].attributes.image} style={{ maxHeight: '44px', maxWidth: '44px', width: 'auto', height: 'auto' }} />
                                                        <Typography style={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: 600 }}>{item.attributes.opportunity.data.attributes.company.data[0].attributes.company_name}</Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellBorder}>
                                                        <Typography style={{ backgroundColor: item.attributes.opportunity.data.attributes.status === 'approved' ? 'rgba(209, 250, 229, 1)' : 'rgb(255, 190, 190)', color: item.attributes.opportunity.data.attributes.status === 'approved' ? 'rgba(5, 150, 105, 1)' : 'rgba(220, 38, 38, 1)', padding: '8px', maxWidth: '83px', textAlign: 'center', borderRadius: '4px', margin: '0 auto' }}>{item.attributes.opportunity.data.attributes.status}</Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellBorder}>
                                                        <Typography className={classes.tableBody}>
                                                            {this.formatDate(item.attributes.created_at)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellBorder}>
                                                        <Typography className={classes.tableBody} data-test-id="invested_amount">
                                                            ${this.numberWithCommas(item.attributes.amount)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellBorder}>
                                                        <Button className={classes.viewBtn} onClick={() => this.viewProfile(item)} data-test-id={'viewProfileBtn' + index}>View</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>

                    </Container> 
                }
                {!loading && portfolioInvestmentList?.length == 0  &&
                    <Container style={{ display: 'flex', flexDirection: 'column', gap: '48px', padding: '40px 0px' }}>
                        <Box style={{ display: 'flex', gap: '10', flexDirection: 'column', justifyContent: 'center', marginLeft: '42px' }}>
                            <Typography style={{ fontSize: '40px', fontFamily: 'Lato', color: 'rgba(34, 34, 34, 1)', fontWeight: 400 }}>
                                Hey <span style={{ color: 'rgba(0, 134, 240, 1)', fontWeight: 600, textTransform: 'capitalize' }}>{userName},<img style={{ height: '50px', width: '50px' }} src={hand} /></span>
                            </Typography>
                            <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontWeight: 400, fontFamily: 'Roboto', top: '208px' }}>Once you make an investment, you’ll be able to track its performance here.</Typography>
                        </Box>

                        <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '25px', justifyContent: 'center' }}>
                            <Box style={{ width: '50%', minWidth: '570px', wordBreak: 'break-word' }}>
                                <Typography style={{ color: 'rgba(111, 111, 112, 1)', fontWeight: 400, fontFamily: 'Roboto', top: '208px', lineHeight:'24px' }}>Building a portfolio is a crucial part of an investment journey. Here what to keep in mind while building a portfolio:</Typography>
                                <ul className={classes.portfolioList}>
                                    <li >
                                        <span className={classes.headingTxt}>Set investment goals:</span>
                                        <span > Define clear objectives for returns, income, growth, or hedging.</span>
                                    </li>
                                    <li >
                                        <span className={classes.headingTxt}>Assess risk tolerance: </span>
                                        <span >Evaluate your comfort level with risk and understand asset class risks.</span>
                                    </li>

                                    <li >
                                        <span className={classes.headingTxt}>Allocate Assets:  </span>
                                        <span >Spread your capital across different alternative asset classes based on risk tolerance, return expectations, and market conditions. Rebalance periodically to maintain balance.</span>
                                    </li>

                                    <li >
                                        <span className={classes.headingTxt}>Take a Long-Term View:  </span>
                                        <span >Alternative assets typically require a longer investment horizon, give preference to a long-term strategy.</span>
                                    </li>
                                </ul>
                            </Box>
                            <Box className={classes.chartBackground}>
                                <Box style={{ width: '209.83px', height: '38px', backgroundColor: 'rgba(0, 105, 188, 1)', borderRadius: '0px 6px 6px 0px', marginTop: '40px', display: 'grid', justifyContent: 'center', alignContent: 'center', gap: '10px' }}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 1)', justifyContent: 'center', fontWeight: 400, fontSize: '19.83px', }}>
                                        Build your portfolio
                                    </Typography>
                                </Box>
                                <Box>
                                    <img src={chartImage} />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Button className={classes.browseOppurtunitiesBtn} onClick={this.navigetoInvestment}>
                                Browse Opportunities
                            </Button>
                        </Box>
                        <Box>
                            <Typography style={{ fontWeight: 600, fontSize: '24px', fontFamily: 'Roboto', marginLeft: '42px' }}>Here are a few ways you can build out your investment portfolio:</Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                            <Box className={classes.boxStyle} >
                                <Box style={{ backgroundColor: 'rgba(243, 243, 242, 1)', height: '80px', width: '80px', borderRadius: '8px' }}>
                                    <img src={startupinvestment} className={classes.imgWidth} style={{ padding: '15px' }} />
                                </Box>
                                <Box>
                                    <Typography className={classes.headingTitle}>Startup Investment</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.descriptionText} >Invest directly into startup of choice </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.boxStyle} >
                                <Box style={{ backgroundColor: 'rgba(226, 244, 224, 1)', height: '80px', width: '80px', borderRadius: '8px' }}>
                                    <img src={fundinvestment} style={{ padding: '15px' }} className={classes.imgWidth}/>
                                </Box>
                                <Box>
                                    <Typography className={classes.headingTitle}>Fund Investment</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.descriptionText} >Invest directly into fund of choice </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.boxStyle} >
                                <Box style={{ backgroundColor: 'rgba(252, 240, 211, 1)', height: '80px', width: '80px', borderRadius: '8px' }}>
                                    <img src={secondaries} style={{ padding: '15px' }} className={classes.imgWidth}/>
                                </Box>
                                <Box>
                                    <Typography className={classes.headingTitle} >Secondaries</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.descriptionText} >Invest in secondaries of high growth startups</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                }

                <Footer navigation={this.props.navigation}/>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(PortfolioManagementWeb);
// Customizable Area End