import React from "react";

// Customizable Area Start

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { imgCheckbox } from "./assets";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { imgF, imglogo } from "../../forgot-password/src/assets";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import Loader from "../../../components/src/Loader.web";

import {
  Paper,
  Grid,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  StyleRules,
  withStyles,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area End

import EmailAccountRegistrationWebController, {
  Props,
} from "./EmailAccountRegistrationController.web";

const styles: StyleRules = {
  // Customizable Area Start
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "500px",
    '@media (max-width: 425px)': {
      width: '310px',
    }
  },
  textStyle: {
    fontWeight: 400,
    fontSize: "14px",
    alignSelf: "center",
    margin: "16px 0px",
    fontFamily: "Roboto",
  },
  headerText: {
    fontWeight: 800,
    fontSize: "40px",
    margin: "16px 0px",
    fontFamily: "Lato"
  },
  errorText: {
    color: "#DC2626",
    fontSize: "14px",
    fontWeight: 400,
    margin: "0px",
    maxWidth: "370px",
    width: '100%'
  },
  labelText: {
    fontWeight: 400,
    fontSize: "14px",
    margin: "0px",
    color: "#222222",
    fontFamily: "Roboto"
  },
  signUpBtn: {
    backgroundColor: "#0069BC",
    padding: "10px 16px",
    textTransform: "none",
    width: "100%",
    maxWidth: '370px',
    height: "60px",
    borderRadius: "12px",
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: 700,
    margin: "16px 0px",
    "&:hover": {
      backgroundColor: "#0069BC",
    },
  },
  imageBox: {
    position: 'absolute',
    height: '100vh',
    width: "100vh",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 425px)': {
      width: '100%',
    }
  },
  logoBox: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '50px',
    paddingLeft:'20px'
  },
  headerMainText: {
    fontSize: '48px',
    maxWidth: '560px',
    width: '100%',
    fontWeight: 800,
    color: '#F2F2F2',
    fontFamily: 'Lato',
    '@media (max-width: 425px)': {
      fontSize: '38px',
    }
  },
  fnameLogo: {
    width: '100%'
  },
  desriptionMainText: {
    fontSize: '18px',
    maxWidth: '560px',
    color: '#F2F2F2',
    width: '100%'
  },
  signupText: {
    maxWidth: "370px",
    width: '100%',
    textAlign: "justify"
  },
  inputMainBox: {
    width: "370px",
    height: "48px",
    borderRadius: "8px",
    '@media (max-width: 425px)': {
      width: '280px',
    }
  },
  rightSideContainer: {
    display: 'flex', 
    justifyContent: 'center',
    '@media (max-width: 425px)': {
      padding: '20px',
    }
  },
  // Customizable Area End
};

export class EmailAccountRegistrationWeb extends EmailAccountRegistrationWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Paper>
        <Loader loading={this.state.loading} />
        <Grid container>
          <Grid item xs={12} md={12} lg={6} style={{ background: 'linear-gradient(180deg, #0086F0 0%, #0069BC 100%)', display: 'flex', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <Box>
              <img className={classes.fnameLogo} src={imgF} alt="posterSignup" />
            </Box>
            <Box className={classes.logoBox} >
              <Typography style={{ fontSize: '32px', fontWeight: 500, color: '#F2F2F2' }} >Welcome to</Typography>
              <img src={imglogo} alt="posterSignup" style={{
                maxHeight: '64px',
                width: 'auto',
                maxWidth: '176px',
                height: 'auto',
              }} />
              <Typography className={classes.headerMainText}>Unlock New Horizons of Alternative Investing</Typography>
              <Typography className={classes.desriptionMainText}>Empower your investment portfolio with limitless choices. Select from tier-1 funds, dynamic startups, and beyond.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className={classes.rightSideContainer}>
            <Box className={classes.rightContainer}>
              <Box className={classes.signupText}>
                <Typography className={classes.headerText}>Sign Up</Typography>
              </Box>
              <FormControl variant="outlined" style={{ margin: "16px 0px" }}>
                <FormHelperText
                  id="email-helper-text"
                  className={classes.labelText}
                >
                  Email
                </FormHelperText>
                <OutlinedInput
                  id="email-input"
                  value={this.state.webEmail}
                  onChange={this.handleEmailChange}
                  aria-describedby="email-helper-text"
                  labelWidth={0}
                  className={classes.inputMainBox}
                  style={{
                    color: this.state.emailError ? "#DC2626" : "",
                  }}
                  onFocus={() => this.setState({ emailError: "" })}
                  onBlur={this.validateEmail}
                />
                {this.state.emailError && (
                  <FormHelperText className={classes.errorText}>
                    {this.state.emailError}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl variant="outlined" style={{ margin: "16px 0px" }}>
                <FormHelperText
                  id="password-helper-text"
                  className={classes.labelText}
                >
                  Password
                </FormHelperText>
                <OutlinedInput
                  id="password-input"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.webPassword}
                  onChange={this.handlePasswordChange}
                  aria-describedby="password-helper-text"
                  labelWidth={0}
                  className={classes.inputMainBox}
                  style={{
                    color: this.state.passwordError ? "#DC2626" : "",
                  }}
                  onFocus={() => this.setState({ passwordError: "" })}
                  onBlur={this.validatePassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {this.state.passwordError && (
                  <FormHelperText className={classes.errorText}>
                    {this.state.passwordError}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControlLabel
                control={this.state.checkBox ? <img src={imgCheckbox} alt="checkbox" onClick={this.handleCheckboxChange} style={{ margin: '2px 8px 16px 25px', height: '22px', width: '22px' }} /> : <CheckBoxOutlineBlankIcon onClick={this.handleCheckboxChange} style={{ margin: '0px 8px 16px 25px' }} />}
                label={
                  <span style={{ textAlign: "justify" }}>
                    <Typography style={{ fontSize: "14px", fontFamily: 'Roboto' }}>
                      By continuing you agree to our{" "}
                      <span style={{ color: "#0069BC", textDecoration: 'none', fontFamily: 'Roboto', fontWeight:"600" }} onClick={() => this.redirectToTermAndCondition('terms_and_conditions')}> Terms & Conditions </span>
                    </Typography>
                    <Typography style={{ fontSize: "14px", fontFamily: 'Roboto' }}>
                      and <span style={{ color: "#0069BC", textDecoration: 'none', fontFamily: 'Roboto', fontWeight:"600" }} onClick={() => this.redirectToTermAndCondition('privacy_policy')}> Privacy Policy </span>
                    </Typography>
                  </span>
                }
              />
              {!this.state.checkBox && this.state.checkBoxError && (
                <FormHelperText className={classes.errorText}>
                  {this.state.checkBoxError}
                </FormHelperText>
              )}

              <Button onClick={this.handleSubmit} className={classes.signUpBtn}>
                Sign Up
              </Button>
              <Typography className={classes.textStyle}>OR</Typography>
              <GoogleOAuthProvider clientId="539417259186-jd2q75art61ah202jjjim4dmegave6nm.apps.googleusercontent.com">
                <GoogleLogin
                  text='continue_with'
                  width='370px'
                  logo_alignment='center'
                  theme='outline'
                  shape="rectangular"
                  onSuccess={(credentialResponse) => this.signUpWithGoogle(credentialResponse)}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                ></GoogleLogin>
              </GoogleOAuthProvider>
              <Typography className={classes.textStyle}>
                Already have an account?{" "}
                <span style={{ color: "#0069BC", textDecoration: 'none', cursor: 'pointer', fontWeight:"600" }} onClick={() => this.redirectToLogin()}>Log In</span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(EmailAccountRegistrationWeb);
// Customizable Area End
