//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area Start
import {
  Grid,
  Box, Typography
} from "@material-ui/core";
import { imgShape } from "../../landingpage/src/assets";
import { connectore, imageHerosectionbackground, imageShapeOne, imageShapeThree, imageShapeTwo, textlabel_0, textlabel_1, textlabel_3, textlabel_2, userImageOne, userImageThree, userImageTwo,view_ellipseHalf } from "./assets";
import Footer from "../../../components/src/Footer.web";
import AppHeader from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start
interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#376fd0",
      contrastText: "#fff",
    },
    error: {
      main: "#f44336",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={{ overflowX: 'hidden' }}>
          <Loader loading={this.state.loading} />
          <Box style={Styles.bgImageStyle}>
            <AppHeader navigation={this.props.navigation}/>
            <Box style={Styles.titleTextMainBox}>
              <img src={imgShape} style={{
                position: 'absolute',
                right: '-50px',
                top: '-15px',
              }} />
              <Typography style={Styles.headerMainText}> We are the <span style={Styles.futureText}>Future</span> of Alternative Investing</Typography>
              <Typography style={Styles.descriptionMainText}>Future for Alts is the manifestation of our vision for the evolution of alternative investing.</Typography>
            </Box>
          </Box>

          <Grid container style={{ display: "flex", justifyContent: "center", padding: '155px 0px 189px' }}>
            <Box style={Styles.contentImgBox}>
              <Box style={{ display: 'flex', alignItems: 'self-start', width: '100%' }}>
                <Box style={{ width: '44%' }}>
                  <Box style={{ backgroundColor: '#9EE4A3', padding: '32px', borderRadius: '16px',gap:"24px" }}>
                    <Typography variant="h6" style={{ fontWeight: 800,fontFamily: 'Lato',fontSize:"32px", color: '#0069BC', lineHeight:"28.4px"}}>
                      Traditional limitation: 
                    </Typography>  
                    <Typography style={{fontFamily:"robot",marginTop:"24px",lineHeight:"27px",fontSize:"18px"}}>
                      Privileged access to alternative investments for institutional investors and fund managers.
                    </Typography>
                  </Box>
                </Box>
                <Box style={{ width: '50%', display: 'flex', alignItems: 'flex-end', marginTop: '4%' }}>
                  <img src={connectore} alt="" style={{ width: '53%', height: '50%' }} />
                </Box>
              </Box>
              <Box style={Styles.bottomMainBox}>
                <Box style={{ width: '50%' }}></Box>
                  <Box style={{ width: '44%' }}>
                    <Box style={{ backgroundColor: '#1185E0', padding: '32px', borderRadius: '16px', gap:"24px",color: 'white' }}>
                      <Typography variant="h6" style={{fontWeight: 800 ,fontFamily: 'Lato',fontSize:"32px",lineHeight:"28.4px"}}>
                          Transformation goal:
                      </Typography>
                      <Typography style={{fontFamily:"robot",lineHeight:"27px", marginTop:"24px",fontSize:"18px"}}>
                        Open up a world of opportunities for retail investors and family offices.
                      </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
             </Grid>

          <Grid container style={{ display: "flex", justifyContent: "center", padding: '0px 0px 189px' }}>
            <Grid container style={{ maxWidth: '1169px', width: '100%', gap: '50px', display: 'flex' }}>
              <Grid item lg={12} style={{ maxWidth: '615px', gap: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography style={{ fontSize: '48px', fontWeight: 600, fontFamily: 'Lato' }}>We are driven by our <span style={{ color: '#0069BC', fontWeight: 800, fontStyle: 'italic' }}>beliefs. </span>
                  We disrupt by <span style={{ color: '#0069BC', fontWeight: 800, fontStyle: 'italic' }}>enabling</span>.</Typography>
                <img src={imageShapeOne} alt="" style={{ height: '100%', maxHeight: '320px' }} />
              </Grid>
              <Grid item lg={12} style={{ maxWidth: '500px' }}>
                <Box className="scroll-box" style={{ display: 'flex', flexDirection: 'column', padding: '32px', border: this.state.activeIndexScroll !== 0 ? 'none' : '1px solid #0069BC', borderRadius: '16px', maxWidth: '500px', minHeight: '130px', gap: '24px', background: this.state.activeIndexScroll !== 0 ? 'transparent' : 'rgba(0, 105, 188, 0.1)' }}>
                  <Typography style={{ color: '#0069BC', fontFamily: 'Lato', fontWeight: 800, fontSize: '32px' }}>{this.state.about_us_data[2]?.attributes.title}</Typography>
                  <Typography style={{ color: '#222222', fontFamily: 'Roboto', fontSize: '18px' }}>{this.state.about_us_data[2]?.attributes.description}</Typography>
                </Box>
                <Box className="scroll-box" style={{ display: 'flex', flexDirection: 'column', padding: '32px', border: this.state.activeIndexScroll !== 1 ? 'none' : '1px solid #0069BC', borderRadius: '16px', maxWidth: '500px', gap: '24px', background: this.state.activeIndexScroll !== 1 ? 'transparent' : 'rgba(0, 105, 188, 0.1)' }}>
                  <Typography style={{ color: '#0069BC', fontFamily: 'Lato', fontWeight: 800, fontSize: '32px' }}>{this.state.about_us_data[0]?.attributes.title}</Typography>
                  <Typography style={{ color: '#222222', fontFamily: 'Roboto', fontSize: '18px' }}>{this.state.about_us_data[0]?.attributes.description}</Typography>
                </Box>
                <Box className="scroll-box" style={{ display: 'flex', flexDirection: 'column', padding: '32px', border: this.state.activeIndexScroll !== 2 ? 'none' : '1px solid #0069BC', borderRadius: '16px', maxWidth: '500px', gap: '24px', background: this.state.activeIndexScroll !== 2 ? 'transparent' : 'rgba(0, 105, 188, 0.1)' }}>
                  <Typography style={{ color: '#0069BC', fontFamily: 'Lato', fontWeight: 800, fontSize: '32px' }}>{this.state.about_us_data[1]?.attributes.title}</Typography>
                  <Typography style={{ color: '#222222', fontFamily: 'Roboto', fontSize: '18px' }}>{this.state.about_us_data[1]?.attributes.description}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ display: "flex", justifyContent: "center", padding: '0px 0px 189px' }}>
            <Grid container style={{ maxWidth: '1169px', width: '100%' }}>
              <Grid item lg={6} style={{ borderWidth: '2px 2px 1px 0px', borderStyle: 'solid', borderColor: '#0069BC', maxWidth: '600px' }}>
                <Typography style={{ fontSize: '48px', fontWeight: 600, maxWidth: '630px', fontFamily: 'Lato' }}>Future for Alts is an <span style={{ color: '#0069BC', fontWeight: 800, fontStyle: 'italic' }}>innovative investment platform </span>
                  that has: </Typography>
                <img src={imageShapeTwo} alt="" />
              </Grid>
              <Grid item lg={6} style={{ maxWidth: '705px' }} >
                <Box className="scroll-box" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: this.state.activeIndexScroll == 3 && '#0069BC' , border: '1px solid #0069BC', borderColor: '#0069BC', borderStyle: 'solid', borderWidth: '2px 0px 1px 1px', maxWidth: '705px', minHeight: '240px' }}>
                  <Typography style={{ color:3 === this.state.activeIndexScroll ? '#FFFFFF' : '#222222', fontFamily: 'Lato', fontWeight: 800, fontSize: '32px', maxWidth: '506px' }}><ol><li>Seamless and accessible investment across asset classes.</li></ol></Typography>
                </Box>
                <Box className="scroll-box" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',background: this.state.activeIndexScroll == 4 && '#0069BC', maxWidth: '705px', border: '1px solid #0069BC', borderColor: '#0069BC', borderStyle: 'solid', borderWidth: '2px 0px 1px 1px', minHeight: '180px' }}>
                  <Typography style={{ color: 4 === this.state.activeIndexScroll ? '#FFFFFF' : '#222222', fontFamily: 'Lato', fontWeight: 600, fontSize: '32px', maxWidth: '506px' }}><ol start={2}><li>Beyond traditional options: Funds, direct investments, and secondaries.</li></ol></Typography>
                </Box>
                <Box className="scroll-box" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',background: this.state.activeIndexScroll == 5 && '#0069BC', maxWidth: '705px', border: '1px solid #0069BC', borderColor: '#0069BC', borderStyle: 'solid', borderWidth: '2px 0px 1px 1px', minHeight: '180px' }}>
                  <Typography style={{ color: 5 === this.state.activeIndexScroll ? '#FFFFFF' : '#222222', fontFamily: 'Lato', fontWeight: 600, fontSize: '32px', maxWidth: '506px' }}><ol start={3}><li>All-in-one platform for comprehensive investment solutions.</li></ol></Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ display: "flex", justifyContent: "center", padding: '0px 0px 189px' }}>
            <Grid style={Styles.ellipsBox}>
              <Grid item lg={8} style={{ display: 'flex', flexDirection: 'column', marginTop: '54px' ,position:"relative",gap:"100px", marginBottom: '10px' }} >
                <Box style={{ display: 'flex', alignItems: 'end', gap: '24px', justifyContent: 'end', marginLeft:"240px", width: '600px' }}>
                  <Box style={{ alignSelf: 'center', position: 'relative', zIndex: 1 }}>
                      <img src={textlabel_0} style={{ margin: '0px -24px 0px 10px' }} />
                      <img src={textlabel_1} style={{ position: 'absolute', zIndex: 1 }} />
                      <Box style={Styles.futureBox}>
                        Digital First
                      </Box>
                  </Box>
                  <Typography style={{ fontSize: '16px', minHeight: '72px', maxWidth: '372px', width: '100%', fontFamily: 'Roboto' }}>{this.state.feature_and_benefit[0]?.attributes.description}</Typography>
                </Box>
                <Box style={{ display: 'flex',marginLeft:"140px", alignItems: 'end', gap: '24px', justifyContent: 'end', width: '674px' }}>
                  <Box style={{ alignSelf: 'center', position: 'relative', zIndex: 1 }}>
                      <img src={textlabel_0} style={{ margin: '0px -24px 0px 10px' }} />
                      <img src={textlabel_2} style={{ position: 'absolute', zIndex: 1 }} />
                      <Box style={Styles.futureBox}>
                        Secondary Market
                      </Box>
                  </Box>
                  <Typography style={{ fontSize: '16px', minHeight: '72px', maxWidth: '372px', width: '100%', fontFamily: 'Roboto' }}>{this.state.feature_and_benefit[1]?.attributes.description}</Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'end', gap: '24px', width: '774px', marginLeft: '40px' }}>
                  <Box style={{ alignSelf: 'center', position: 'relative', zIndex: 1 }}>
                      <img src={textlabel_0} style={{ margin: '0px -24px 0px 10px' }} />
                      <img src={textlabel_3} style={{ position: 'absolute', zIndex: 1 }} />
                      <Box style={Styles.futureBox}>
                        Top-tier Access
                      </Box>
                  </Box>
                  <Typography style={{ fontSize: '16px', minHeight: '72px', maxWidth: '503px', width: '100%', fontFamily: 'Roboto' }}>{this.state.feature_and_benefit[2]?.attributes.description}</Typography>
                </Box>
              </Grid>



              <Grid item lg={4} style={{ flexDirection: 'column', display: 'flex', maxWidth: '294px',position:"relative",alignItems:"center", marginRight: '-30px' }}>
                <img src={imageShapeThree} width="94px" height="93px" style={{alignSelf:"flex-start", marginLeft: '40px'}} alt="" />
                <Box >
                  <Typography style={{ fontSize: '64px', fontWeight: 700, fontFamily: 'Lato', fontStyle: 'italic',marginTop:"20px", color: '#0069BC',lineHeight:"84px", width: '100%', marginBottom: '24px'}}>Features
                    & Benefits</Typography>
                  <Typography style={{ fontFamily: 'Roboto', fontSize: '16px', color: '#828282', maxWidth: '300px', width: '100%', right:"100px", }}>As the foremost digital platform for alternative investments, Future for Alts offers an unparalleled experience for users. What does this mean for you?</Typography>
                </Box>
              </Grid>




            </Grid>
          </Grid>



          <Grid container style={{ display: "flex", justifyContent: "center", padding: '0px 0px 189px' }}>
            <Grid container style={{ maxWidth: '1169px', width: '100%' }}>
              <Typography style={{ color: '#0069BC', fontSize: '24px', fontWeight: 500, fontFamily: 'Roboto', maxWidth: '970px' }}>Success of any platform is contingent upon the proficiency of the team constructing it.</Typography>
              <Typography style={{ fontSize: '48px', fontWeight: 700, fontFamily: 'Lato', maxWidth: '970px' ,margin:"10px 0px"}}>Meet the team constructing this platform with you in mind.</Typography>
              <Grid container >
                <Grid item lg={4} >
                  <Box style={{ display: 'flex', flexDirection: 'column', alignContent: "space-between", maxWidth: '370px' }}>
                    <Box style={{height: "180px"}}>
                      <Typography style={{ color: '#0069BC', fontSize: '40px', fontWeight: 800, fontFamily: 'Lato', fontStyle: 'italic' }}>Experienced</Typography>
                      <Typography style={{ fontFamily: 'Roboto', fontSize: '20px' }}><ul><li>Decades of expertise</li><li>
                        Investment, product, compliance</li></ul>
                      </Typography>
                    </Box>
                    
                      <Box style={{  display: 'flex',minWidth: '370px', flexDirection: 'column', marginTop:"10%",minHeight: '201px' }}>
                        <img src={userImageOne} style={{width: "350px", height: "400px"}} alt="" />
                        <Typography style={{ fontWeight: 700, fontSize: '24px', fontFamily: 'Lato' }}>James Smith</Typography>
                        <Typography style={{ color: '#0069BC', fontFamily: 'Roboto', fontSize: '18px', fontWeight: 500 }}>Director, CEO</Typography>
                      </Box>
                    
                  </Box>
                </Grid>
                <Grid item lg={4} >
                  <Box style={{ display: 'flex', flexDirection: 'column', maxWidth: '370px', minHeight: '201px' }}>
                  <Box style={{height: "180px",marginBottom:"10%"}}>
                    <Typography style={{ fontFamily: 'Lato',color: '#0069BC', fontSize: '40px', fontWeight: 800, fontStyle: 'italic' }}>Commitment</Typography>
                    <Typography style={{  fontSize: '20px',fontFamily: 'Roboto'}}><ul><li>Best opportunities and exceptional results</li><li>
                      Valuable insights and guidance</li></ul>
                    </Typography>
                  </Box>  
                      <Box style={{flexDirection: 'column', display: 'flex', minWidth: '370px', minHeight: '201px' }}>
                        <img src={userImageTwo} alt="" style={{width: "350px", height: "400px"}} />
                        <Typography style={{ fontSize: '24px', fontWeight: 700, fontFamily: 'Lato' }}>James Smith</Typography>
                        <Typography style={{ fontWeight: 500, fontFamily: 'Roboto', fontSize: '18px', color: '#0069BC' }}>Director, CEO</Typography>
                      </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} >
                  <Box style={{ display: 'flex', maxWidth: '370px', minHeight: '201px' ,flexDirection: 'column' }}>
                    <Box  style={{marginBottom:"10%",height: "180px"}}>
                      <Typography style={{ fontSize: '40px', color: '#0069BC', fontWeight: 800, fontFamily: 'Lato', fontStyle: 'italic' }}>Customer-centric</Typography>
                      <Typography style={{ fontWeight:400,fontFamily: 'Roboto', fontSize: '20px' }}><ul><li>Superior investment experience</li><li>
                        Meets your unique requirements</li></ul>
                      </Typography>
                    </Box>
                    
                      <Box style={{ flexDirection: 'column', display: 'flex', minWidth: '370px', minHeight: '201px' }}>
                        <img src={userImageThree} alt="" style={{width: "350px", height: "400px"}} />
                        <Typography style={{ fontFamily: 'Lato', fontSize: '24px', fontWeight: 700 }}>James Smith</Typography>
                        <Typography style={{ color: '#0069BC', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 500 }}>Director, CEO</Typography>
                      </Box>
                  
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid container style={{ display: "flex", justifyContent: "center", padding: '40px 0px 0px' }}>
            <Grid container style={{ maxWidth: '1169px', width: '100%' }}>
              
              
              
            </Grid>
          </Grid> */}

          
          <Footer navigation={this.props.navigation}/>
        </div>
      </ThemeProvider>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const Styles = {
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 15px',
    justifyContent: 'space-between',
    width: '90vw'
  },
  signUpButton: {
    color: '#0069BC',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    fontSize: '14px',
    height: '40px',
    border: 'none'
  },
  forMenues: {
    display: 'flex',
    gap: '16px',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    }
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 15px',
    justifyContent: 'space-between',
    width: '90vw'
  },
  ImgTagStyle: {
    width: '100px',
    height: '100px',
    padding: '10px'
  },
  bgImageStyle: {
    backgroundImage: `url(${imageHerosectionbackground})`,
    minHeight: '871px',
    backgroundRepeat: "round",
    backgroundSize: "cover",
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "0 0 75px 0",
    alignItems: 'center',
    gap: '123px',
    '@media (max-width: 700px)': {
      gap: '50px'
    }
  },
  ellipsBox: {
    backgroundImage: `url(${view_ellipseHalf})`,
    backgroundSize: "794px", 
    backgroundPosition: 'top center', 
    backgroundRepeat: "no-repeat",
    display: 'flex',
    justifyContent:"space-between",
    width: '1169px',
    alignItems:"flex-end",
    height: '650px',
  },
  futureBox: {
    border: '2px solid #0069BC',
    borderRadius: '40px',
    padding: '24px 40px',
    fontSize: '24px',
    fontFamily: 'Lato',
    fontWeight: 700,
    color: 'rgba(0, 105, 188, 1)',
    textAlign: 'center',
    marginTop: '-32px',
    background: 'white',
    position: 'relative',
    whiteSpace: 'nowrap'
  },
  titleTextMainBox: {
    maxWidth: '1170px',
    width: '100%',
    minHeight: '255px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    gap: '40px'
  },
  contentImgBox: {
    maxWidth: '1169px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentBox: {
    padding: '50px 0px',
    maxWidth: '1169px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerMainText: {
    fontSize: '56px',
    fontWeight: 600,
    fontFamily: "lato",
    textAlign: 'center',
    color: '#fff',
    maxWidth: '550px',
    width: '100%'
  },
  futureText: {
    color: '#00E644',
    fontWeight: 700,
  },
  descriptionMainText: {
    fontSize: '20px',
    fontFamily: "Roboto",
    textAlign: 'center',
    color: '#fff',
  },
  secountPartBox: {
    width: '100%'
  },
  limitationBox: {
    background: 'linear-gradient(96.34deg, rgba(0, 230, 68, 0.25) 0%, rgba(0, 230, 68, 0.5) 100%)',
    padding: '32px',
    borderRadius: '16px',
    width: '50%'
  },
  goalBox: {
    background: 'linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)',
    padding: '32px',
    borderRadius: '16px',
    width: '50%'
  },
  limitationText: {
    fontSize: '32px',
    fontWeight: 700,
    fontFamily: "lato",
    color: '#0069BC'
  },
  goalText: {
    fontSize: '32px',
    fontWeight: 700,
    fontFamily: "lato",
    color: '#fff'
  },
  limitDescriptionTxt: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: "Roboto",
    width: '500px'
  },
  goalDescriptionTxt: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#fff",
    width: '500px'
  },
  bottomMainBox: {
    display: 'flex',
    alignItens: 'end',
    justifyContent: 'center',
    width: '100%',
    marginLeft:"-150px"
  },
  connectImage: {
    marginTop: '50px',
    width: '50%'
  },
  mainTextThirdPart: {
    fontSize: '48px',
    fontWeight: 600,
    fontFamily: "Roboto",
    color: "#000",
  }
}
// Customizable Area End
