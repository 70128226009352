import React from "react";

// Customizable Area Start

import { Box, Paper, Typography } from "@material-ui/core";
import { StyleSheet } from "react-native";
import Loader from "../../../components/src/Loader.web";
import TopNavBar from "../../../components/src/TopNavbar.web";

// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { companyPolicyData, isLoading } = this.state

    return (
      <>
        <TopNavBar navigation={this.props.navigation} />
        <Paper style={{ display: 'flex', justifyContent: 'center', height: 'auto', paddingBottom:'45px', boxShadow: 'none' }}>
          <Loader loading={isLoading} />
          {companyPolicyData ?
            <Box style={{ display: 'flex', flexDirection: 'column', width: '90vw', gap: '25px', padding: '20px 15px' }}>
              <Typography style={{ fontSize: '64px', fontWeight: 700, color: '#222222', fontFamily: 'Lato', justifyContent: 'center', display: 'flex' }}>
                {companyPolicyData.policy_type === "terms_and_conditions" && "Terms & Conditions"}
                {companyPolicyData.policy_type === "privacy_policy" && "Privacy Policy"}
                {companyPolicyData.policy_type === "disclaimer" && "Disclaimer"}
              </Typography>
              <div
                style={{ fontSize: '18px', fontWeight: 400, color: '#828282', fontFamily: 'Roboto' }}
                dangerouslySetInnerHTML={{ __html: companyPolicyData.content }}
              />
            </Box> :
            <Typography>Please try again!</Typography>
          }
        </Paper>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
});
// Customizable Area End
