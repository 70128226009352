import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
    shape,
    imgArraow,
    imgBargraph,
    imgReport,
    imgShape,
    imgGroupSection,
    image_shape,
    frameShape,
    group_text02
} from "./assets";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { imageHerosectionbackground } from "../../contentmanagement/src/assets";
import Footer from "../../../components/src/Footer.web";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);
import 'swiper/swiper-bundle.css';
// Customizable Area End

import LandingPageScreenController, {
    Props,
} from "./LandingPageScreenController.web";

const styles: StyleRules = {
    // Customizable Area Start
    backgroundImageStyle: {
        minHeight: '871px',
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundImage: `url(${imageHerosectionbackground})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainHeaderTextStyle: {
        fontSize: '48px',
        fontWeight: 500,
        fontFamily: "Lato",
        textAlign: 'center',
        color: '#fff'
    },
    futureTextStyle: {
        color: '#00E644',
        fontSize: '48px',
        fontWeight: 900,
        fontStyle: 'italic'
    },
    titrleTextMainBoxStyle: {
        maxWidth: '1170px',
        width: '100%',
        minHeight: '298px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    descriptionMainTextStyle: {
        fontSize: '20px',
        fontFamily: "Roboto",
        textAlign: 'center',
        color: '#fff',
    },
    groupcoinvesterStyle: {
        height: "243px",
        width: "1164px",
    },
    investorBoxStyle: {
        borderRadius: '64px 0px 64px 0px',
        minHeight: '243px',
        maxWidth: '1169px',
        width: '100%',
        background: '#121212',
        position: 'absolute',
        top: '694px'
    },
    companyBoxStyle: {
        borderRadius: '64px 0px 64px 0px',
        minHeight: '376px',
        maxWidth: '1169px',
        width: '100%',
        background: '#121212',
    },
    wealthBoxStyle: {
        padding: '148px 0px',
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px'
    },
    companyBtnStyle: {
        borderRadius: "12px",
        height: "48px",
        fontSize: '18px',
        fontFamily: "Roboto",
        color: '#FFFFFF',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#0069BC",
        },
    },
    swiper: {
        '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
            display: 'none !important'
        },
        '& .swiper-button-next, .swiper-container-rtl .swiper-button-prev': {
            display: 'none !important'
        },
        '& .swiper-slide' :{
            marginTop:'20px',
        },
        '& .swiper-wrapper': {
            height: 'auto'
        }
    },
    containerImageStyle: {
        position: 'relative',
        width: '100%',
        maxWidth: '970px',
        margin: '0 auto'
    },
    frameImgStyle: {
        width: '794px',
        height: '780px',
        position: 'absolute',
        top: '54%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity:0.3
    },
    groupSectionImgStyle: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        zIndex: 1,
        padding: '240px 10px 148px',
        '@media (max-width: 500px)': {
            padding: '32px 16px'
        }
    },
    callBtn: {
        borderRadius: "12px",
        height: "60px",
        maxWidth: '200px',
        width: '100%',
        fontSize: '20px',
        fontFamily: "Roboto",
        color: '#FFFFFF',
        textTransform: 'none',
        fontWeight: 700,
        border: '1px solid rgba(255, 255, 255, 1)',
    },
    investNowBtn: {
        borderRadius: "12px",
        height: "60px",
        maxWidth: '200px',
        width: '100%',
        fontSize: '20px',
        fontFamily: "Roboto",
        color: 'rgba(0, 105, 188, 1)',
        textTransform: 'none',
        background: 'rgba(255, 255, 255, 1)',
        fontWeight: 700,
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
        },
    },
    section: {
        scrollSnapAlign: 'start',
        animation: 'fadeIn 0.5s ease-out forwards',
    }
    // Customizable Area End
};

export class HomeScreenWeb extends LandingPageScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <div style={{'overflowX': 'hidden'}}>
                <section className={classes.section}>
                <Box className={classes.backgroundImageStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <Box className={classes.titrleTextMainBoxStyle} style={{ position: 'relative' }}>
                        <img src={imgShape} style={{
                            position: 'absolute',
                            right: '-50px',
                            top: '-15px',
                        }} />
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '40px',marginTop:"126px" }}>
                            <Typography className={classes.mainHeaderTextStyle}> Seamlessly enter the world of <span className={classes.futureTextStyle} style={{fontWeight:600}}>Alternative Investments</span> with <span className={classes.futureTextStyle} style={{fontWeight:600}}>Future for Alts.</span></Typography>
                            <Typography className={classes.descriptionMainTextStyle}>The world's only platform offering both, a website and application, for effortless access.</Typography>
                            {this.state.token !== null && <Box style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '62px' }}>
                                <Button className={classes.callBtn} onClick={this.handleScheduleCall}>Schedule a Call</Button>
                                <Button className={classes.investNowBtn} onClick={this.handleInvestNow}>Invest Now</Button>
                            </Box>}
                        </Box>
                    </Box>
                    <Box className={classes.investorBoxStyle}>
                        <Typography style={{ fontSize: '28px', padding: '32px', fontWeight: 700, fontFamily: 'Lato', color: '#FFFFFF' }}>Our Co-Investors</Typography>
                        <img src={imgArraow} />
                            <Swiper
                                slidesPerView={5}
                                navigation
                                autoplay={{ delay: 700 }} 
                                className={classes.swiper}
                                speed={1500}
                                loop
                            >
                                {this.state.investorDataRecords.map((data: any, index: number) => (
                                    <SwiperSlide style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 'auto',
                                    }}>
                                        <img
                                            src={data.attributes?.image}
                                            key={index}
                                            alt={data.attributes.company_name}
                                            style={{
                                                width: '30%',
                                            }}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                    </Box>
                </Box>
                </section>
                <section className={classes.section}>
                <Grid item container style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12}>
                    <div className={classes.containerImageStyle}>
                        <img src={frameShape} className={classes.frameImgStyle} />
                        <img src={group_text02} className={classes.groupSectionImgStyle} />
                    </div>
                  </Grid>
                </Grid>
                </section>
                <section className={classes.section}>
                    <Grid item container style={{ display: 'flex', justifyContent: 'center' }}>

                    <Grid
                        item
                        xs={12}
                        style={{
                            background: 'linear-gradient(92.83deg, #0086F0 0%, #0069BC 100%)',
                            width: "100%",
                            alignItems: 'center',
                            color: "#fff",
                            fontWeight: "600",
                            textAlign: "center",
                            fontFamily: "Lato",
                            lineHeight: "68px",
                            position: "relative",
                            display: 'flex',
                            justifyContent: 'center',
                            minHeight: "320px",
                            marginBottom: '148px'
                        }}
                    >
                        <img
                            alt="group_shape"
                            src={shape}
                            style={{
                                left: "0px",
                                height: "198px",
                                position: "absolute",
                                top: "0px",
                                width: "228px",
                            }}
                        />
                        <Typography style={{ fontSize: "48px", fontWeight: 400, fontFamily: 'Lato' }}>
                            Premier digital first platform for <br />
                            <span
                                style={{
                                    fontStyle: "italic",
                                    color: "#00E644",
                                    fontWeight: 600,
                                }}
                            >
                                Alternative Investing.
                            </span>
                        </Typography>
                        <img
                            src={image_shape}
                            alt="group_shape"
                            style={{
                                right: "16px",
                                position: "absolute",
                                transform: " scaleY(1)",
                                height: "198px",
                                width: "228px",
                                bottom: "0px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Box className={classes.companyBoxStyle}>
                                <Typography className={classes.mainHeaderTextStyle} style={{ padding: '32px' }}>
                                    One <span className={classes.futureTextStyle} style={{ color: '#0069BC' }}>Platform, </span>
                                    Multiple <span className={classes.futureTextStyle} style={{ color: '#0069BC' }}>Opportunities</span>
                                </Typography>
                                <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '24px', justifyContent: 'center', margin: '16px 0px 60px' }}>
                                    <Button className={classes.companyBtnStyle} style={{ width: '125px', backgroundColor: this.state.selectedCompanyType === 'startup' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'startup' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("startup")}>Startup</Button>
                                    <Button className={classes.companyBtnStyle} style={{ width: '180px', backgroundColor: this.state.selectedCompanyType === 'funds' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'funds' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("funds")}>Venture Funds</Button>
                                    <Button className={classes.companyBtnStyle} style={{ width: '164px', backgroundColor: this.state.selectedCompanyType === 'secondary' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'secondary' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("secondary")}>Secondaries</Button>
                                </Box>
                                <Box  >
                                    <Swiper
                                        slidesPerView={5}
                                        navigation
                                        autoplay={{ delay: 700 }}
                                        speed={1500}
                                        className={classes.swiper}
                                        loop
                                    >
                                        {this.state.companyData.map((data: any, index: number) => (
                                            <SwiperSlide style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 'auto',
                                            }}>
                                                <img
                                                    src={data.attributes?.image}
                                                    key={index}
                                                    alt={data.attributes.company_name}
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                </section>

                <section className={classes.section}>                
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Box className={classes.wealthBoxStyle} >
                        <Typography style={{ color: "#222222", fontSize: "42px", maxWidth: '1170px', width: '100%', fontWeight: 500, fontFamily: 'Lato', alignSelf: 'start' }}>
                            Cultivate Long-Term Wealth Generation with{"  "}
                            <span
                                style={{
                                    fontStyle: "italic",
                                    fontWeight: 600,
                                    color: "#0069BC",
                                }}
                            >
                                Venture Investing
                            </span>
                        </Typography>
                        <Grid item container >
                            <Grid item lg={6} style={{ gap: '40px', display: 'flex', flexDirection: 'column',justifyContent:"space-between" }} >
                                <Typography style={{ fontSize: "36px", fontWeight: 500, fontFamily: 'Lato' }}>
                                    Venture investing stands distinguished for its steadfast
                                    commitment to long-term.
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontSize: "20px",fontWeight:400, color: '#828282',maxWidth:"510px" }}>
                                    Leveraging our industry expertise and investment focus, <b style={{fontWeight:700}}>we excel
                                        at identifying and accessing exclusive top-tier investment
                                        opportunities</b> that are typically inaccessible.
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', color: '#828282', fontSize: "18px",maxWidth:"510px" }}>
                                    We bring these opportunities within your reach, <b>enabling
                                        long-term wealth generation.</b>
                                </Typography>
                            </Grid>
                            <Grid item lg={6} style={{ gap: '24px', display: 'flex', flexDirection: 'column',alignItems:"end" }}>
                                <Box
                                    style={{
                                        background: "linear-gradient(60deg, #0086F0 , #0069BC)",
                                        color: "#FFFF",
                                        borderRadius: "16px",
                                    width:"451px",
                                        height:"333px",
                                        alignContent:"center"
                                    }}
                                >
                                    <Box style={{maxWidth:"363px",margin:"auto" }}>
                                    <Typography style={{ fontSize: "28px",lineHeight:"48px", fontFamily: 'Lato', color: '#FFFFFF', fontWeight: 500}}>
                                        Venture investments can deliver an{" "}
                                        <span style={{ color: "#00EC44", fontStyle: "italic", fontWeight: 600 }}>
                                            average return of 55 times
                                        </span>{" "}
                                        the initial investment over a holding period of approximately
                                        eight years.
                                    </Typography>
                                    </Box>
                                    
                                </Box>
                                <Typography style={{ fontSize: "16px", color: '#828282', fontFamily: 'Roboto', fontWeight: 500,maxWidth:"451px"}}>
                                    Past performance is not indicative of future results. The
                                    conclusive data is driven by analysis of multiple data sets.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                </section>

                <section className={classes.section} style={{background: "linear-gradient(60deg, #0086F0 , #0069BC)"}}>
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Box className={classes.wealthBoxStyle}>
                        <Typography style={{ fontSize: "48px", color: '#FFFFFF', width: '100%', fontWeight: 600, fontFamily: 'Lato', alignSelf: 'start' }}>
                            Broaden your{" "}
                            <span style={{ color: "#00E644", fontStyle: "italic", fontWeight: 800, }} >
                                Investment Portfolio
                            </span>{" "}
                            by Diversifying
                        </Typography>
                        <Grid item container style={{display:"flex",gap:"87px"}}>
                            <Grid item lg={5} style={{maxWidth:"45%"}}  >
                                <img src={imgBargraph} alt="bargraph" width={'540px'} height={'459px'} />
                                <Box style={{ padding: '24px 0px'}}>
                        <Typography style={{ fontFamily: 'Roboto', color: '#ffffff', fontSize: "16px", fontWeight: 500 }}>Hypothetical model portfolios shown above are for illustrative purposes only. Past performance is not indicative of future results</Typography>
                    </Box>
                            </Grid>
                            <Grid item lg={5} style={{ flexDirection: 'column', gap: '40px', display: 'flex',maxWidth:"45%" ,maxHeight:"431px"}}>
                                <Typography style={{ fontSize: "40px", fontFamily: 'Lato', fontWeight: 800, color: '#FFFFFF' }}>
                                    Diversify beyond startups, encompassing funds and secondary
                                    investments.
                                </Typography>
                                <Typography style={{ fontSize: "18px", fontFamily: 'Roboto', color: '#F2F2F2', fontWeight: 400 }}>
                                    Accessing top-tier alternative investments enables
                                    diversification and potentially superior risk-adjusted
                                    returns, a strategy employed by large institutional investors
                                    to outperform traditional stock and debt portfolios.
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', fontSize: "18px", color: '#F2F2F2', fontWeight: 700 }}>
                                    Now, individuals, small institutions, and family offices can
                                    also benefit from this strategy through our platform.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    
                   
                </Grid>
                <Grid  lg={6}  >
                   
                    </Grid>
                </section>

                <section className={classes.section}>
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                   
                </Grid>
                </section>
                <section className={classes.section}>
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Box className={classes.wealthBoxStyle} style={{ gap: '20px' }}>
                        <Typography style={{ fontSize: "48px", color: '#222222', maxWidth: '1006px', width: '100%', fontWeight: 600, fontFamily: 'Lato', alignSelf: 'start' }}>
                            <span style={{ color: "#0069BC", fontStyle: "italic", fontWeight: 700 }} >
                                Venture Investments:{" "}
                            </span>
                            Surpassing PE Funds with Superior Returns
                        </Typography>
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                            <Typography style={{ fontSize: "24px", fontFamily: 'Lato', color: '#222222', fontWeight: 600 }}>
                                There has been no better time to start investing in alternative
                                asset classes.
                            </Typography>
                            <Typography style={{ fontFamily: 'Roboto', fontSize: "18px", color: '#828282', fontWeight:400}}>
                                Over the past two decades, private markets have consistently
                                outperformed their corresponding public market indexes.<br /> Private
                                equity (PE) has been a prominent outperformer, but venture
                                investing has outperformed private equity (PE) with even higher
                                returns in the last decade.
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '24px',marginTop:"60px" }}>
                            <img src={imgReport} alt="report" />
                            <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                <Typography style={{ color: '#828282', fontWeight: 600, fontSize: "18px", fontFamily: 'Roboto' }}>
                                    Past performance is not indicative of future results.
                                </Typography>
                                <Typography style={{ fontFamily: 'Roboto', color: '#828282', fontSize: "18px", fontWeight: 600 }}>
                                    Source : Mckinsey & Company
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                </section>
                <section className={classes.section}>
                <Footer navigation={this.props.navigation}/>
                </section>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(HomeScreenWeb);
// Customizable Area End
