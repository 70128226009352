import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import jwt, { JwtPayload } from 'jsonwebtoken';
import { toast } from 'react-toastify';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webEmail: string;
  loading: boolean;
  webPassword: string;
  checkBox: boolean;
  showPassword: boolean;
  emailError: string;
  passwordError: string;
  checkBoxError: string;
  registerdEmail: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailAccountRegistrationApiCallId: string = "";
  signUpWithGoogleApiCallId: string = '';
  getVerificationMailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webEmail: "",
      loading: false,
      webPassword: "",
      checkBox: false,
      showPassword: false,
      emailError: "",
      passwordError: "",
      checkBoxError: "",
      registerdEmail: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.emailAccountRegistrationApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
          setStorageData("registerdEmail", this.state.webEmail)
          setStorageData("signupToken", responseJson.meta.token)
          this.props.navigation.navigate("EmailConfirmation")
        } else {
          toast.error(responseJson.errors[0]);
        }
      } else if (apiRequestCallId === this.signUpWithGoogleApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
          toast.success(responseJson.meta.message);
          setTimeout(() => {
            this.props.navigation.navigate("EmailAccountLoginWeb")
          }, 2000);
        } else {
          toast.error(responseJson.errors[0]);
        }
      } else if (apiRequestCallId === this.getVerificationMailApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
          toast.success(responseJson.meta.message);
        } else {
          toast.error(responseJson.errors[0]);
        }
      }
    }
    // Customizable Area End
  }
  
  async componentDidMount() {
    // Customizable Area Start
    const getMail = await getStorageData("registerdEmail")
    this.setState({
      registerdEmail: getMail
    })
    // Customizable Area End
  }

  // Customizable Area Start
  validateEmail = () => {
    const { webEmail } = this.state;
    if (!webEmail) {
      this.setState({ emailError: "Email is required" });
      return false;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(webEmail)) {
      this.setState({ emailError: "Invalid email address" });
      return false;
    }
    this.setState({ emailError: "" });
    return true;
  };

  validatePassword = () => {
    const { webPassword } = this.state;
    if (!webPassword) {
      this.setState({ passwordError: configJSON.labelPassword + ' is required' });
      return false;
    }
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(webPassword)) {
      this.setState({ passwordError: configJSON.labelPassword + ' must contain at least 8 characters, 1 special character, 1 number, 1 uppercase and 1 lowercase letter' });
      return false;
    }
    this.setState({ passwordError: "" });
    return true;
  };

  validateCheckBox = () => {
    const { checkBox } = this.state;
    if (!checkBox) {
      this.setState({
        checkBoxError:
          "Please agree to Terms & Conditions and Privacy Policy to continue",
      });
      return false;
    }
    this.setState({ checkBoxError: "" });
    return true;
  };

  handleEmailChange = (event: any) => {
    this.setState({
      webEmail: event.target.value,
    });
  };

  handlePasswordChange = (event: any) => {
    this.setState({
      webPassword: event.target.value,
    });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      checkBox: !prevState.checkBox,
    }));
  };

  handleSubmit = () => {
    const isEmailValid = this.validateEmail();
    const isPasswordValid = this.validatePassword();
    const isCheckBoxChecked = this.validateCheckBox();

    if (!isEmailValid || !isPasswordValid || !isCheckBoxChecked) {
      // Validation failed, do not proceed with submission.
      return;
    } else {
      this.signUp()
    }
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword, // Update the state variable
    }));
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  navigateBack = () => {
    this.props.navigation.navigate("EmailAccountRegistrationWeb")
  }

  signUp = () => {
    this.setState({ loading: true });

    const header = { "Content-Type": "application/json" };
    const data = {
      "type": "email_account",
      "attributes": {
        "email": this.state.webEmail,
        "password": this.state.webPassword,
        "terms_and_conditions": this.state.checkBox,
        "privacy_policy": this.state.checkBox
      }
    }
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.emailAccountRegistrationApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account/accounts");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  signUpWithGoogle = (response: any) => {
    this.setState({ loading: true });

    const token = response.credential
    const decodedToken = jwt.decode(token) as JwtPayload | null;

    const header = { "Content-Type": "application/json" };
    const payload = {
      "type": "social_account",
      "attributes": {
        "email": decodedToken?.email
      }
    }
    const httpBody = { data: payload };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.signUpWithGoogleApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account/accounts");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getVerificationMail = async () => {
    this.setState({ loading: true });
    const email = await getStorageData("registerdEmail");
    const signupToken = await getStorageData("signupToken");
    const header = { "Content-Type": "application/json", token: signupToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getVerificationMailApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/?email=" + email);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEmailConfirmation = () => {
    removeStorageData("signupToken");
    removeStorageData("registerdEmail");
    toast.success("Signed up successfully");
    this.props.navigation.navigate("EmailAccountLoginWeb");
  }

  redirectToTermAndCondition = (redirectPage: string) => {
    this.props.navigation.navigate("TermsConditions", {
      type: redirectPage
    });
  }

  redirectToLogin = () => {
    const goToLogin: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToLogin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginWeb");
    goToLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(goToLogin);
  }
  // Customizable Area End
}
