import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { frameShape, imgShape, navLogo } from "../../landingpage/src/assets";
import { imageHerosectionbackground, imgEllips } from "../../contentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
import { imgFive, imgFour, imgOne, imgThree, imgTwo, imgZero,GroupArrowBig,imgBigTwo,imgBigZero,imgBigOne } from "./assets";
import Footer from "../../../components/src/Footer.web";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// Customizable Area End

import StartupInvestmentController, {
    Props,
} from "./StartupInvestmentController.web";

const styles: StyleRules = {
    // Customizable Area Start
    bgImageStyle: {
        backgroundImage: `url(${imageHerosectionbackground})`,
        minHeight: '871px',
        backgroundRepeat: "round",
        backgroundSize: "cover",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '123px',
        '@media (max-width: 1024px)': {
            gap: '40px'
        }
    },
    titleTextMainBox: {
        maxWidth: '1170px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative'
    },
    headerMainText: {
        fontSize: '56px',
        fontWeight: 400,
        fontFamily: "lato",
        textAlign: 'center',
        color: '#fff',
        maxWidth: '925px',
        width: '100%'
    },
    futureText: {
        color: '#00E644',
        fontWeight: 700,
        fontStyle: 'italic'
    },
    opportunitiesBtn: {
        fontSize: '20px',
        fontFamily: "Roboto",
        fontWeight: 700,
        color: 'rgba(0, 105, 188, 1)',
        maxWidth: '319px',
        width: '100%',
        height: '60px',
        padding: '10px 12px',
        borderRadius: '12px',
        textTransform: 'none',
        background: 'rgba(255, 255, 255, 1)',
        '&:hover': {
            background: 'rgba(255, 255, 255, 1)',
        }
    },
    startupBox: {
        maxWidth: '370px',
        height: 'max-content',
        width: '100%',
        padding: '32px',
        background: 'rgba(229, 240, 248, 1)',
        borderRadius: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        position: 'relative'
    },
    ellipsBox: {
        backgroundImage: `url(${imgEllips})`,
        backgroundSize: "806px",
        backgroundPositionX: 'center',
        backgroundRepeat: "no-repeat",
        maxWidth: '1169px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative'
    },
    futureBox: {
        border: '2px solid rgba(0, 105, 188, 1)',
        borderRadius: '80px',
        padding: '24px 40px',
        fontSize: '24px',
        fontFamily: 'Lato',
        fontWeight: 700,
        color: 'rgba(0, 105, 188, 1)',
        textAlign: 'center',
        marginTop: '-32px',
        background: 'white',
        position: 'relative'
    },
    imageContainer: {
        position: 'absolute',
        width: '100%',
        maxWidth: '400px',
        left: '34%',
        top: '182px'
    },
    frameImg: {
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity:"0.2"
    },
    logoImg: {
        width: '100%',
        maxWidth: '221px',
        left: '25%',
        position: 'relative',
        zIndex: 1,
        padding: '160px 10px 80px',
        '@media (max-width: 500px)': {
            padding: '32px 16px'
        }
    },
    textStyle: {
        fontWeight: 500,
        fontSize: '16px',
        fontFamily: 'Roboto'
    },
    Imgnumbers :{
        height:"154px",
        width:"75px"
    },
    FundBox :{
        fontSize: '32px',
        fontFamily: 'Lato',
        fontWeight: 600,
        position: 'relative',
        height: '248px',
        backgroundColor: '#F2F8FC',
        alignContent:"end",
        width: '100%',
        borderRadius: '16px',
        overflow: 'hidden'
    },
    investBtn: {
        fontSize: '20px',
        fontFamily: "Roboto",
        fontWeight: 700,
        color: '#ffffff',
        maxWidth: '319px',
        width: '100%',
        height: '60px',
        padding: '10px 12px',
        borderRadius: '12px',
        textTransform: 'none',
        background: 'rgba(0, 105, 188, 1)',
        '&:hover': {
            background: 'rgba(0, 105, 188, 1)',
        }
    }
    // Customizable Area End
};

export class StartupInvestmentWeb extends StartupInvestmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props;
        const { startupInvestData, opportunityData, feeStructureData } = this.state;

        return (
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <Loader loading={this.state.loading} />
                <Box className={classes.bgImageStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <Box className={classes.titleTextMainBox}>
                        <img src={imgShape} style={{
                            position: 'absolute',
                            right: '0px',
                            top: '-15px',
                        }} />
                        <Typography className={classes.headerMainText}> Experience the benefits of <br/> <span className={classes.futureText}>Direct Startup Investing </span></Typography>
                    </Box>
                    <Button className={classes.opportunitiesBtn} onClick={this.startupInvestmentDetails}>View Available Opportunities</Button>
                </Box>

                <Grid container style={{ display: "flex", justifyContent: "center", padding: '40px 0px', margin: '100px 0px' }}>
                    <Grid className={classes.ellipsBox}>
                        <Box style={{ alignSelf: 'center', position: 'relative', zIndex: 1, top: '-75px' }}>
                            <img src={imgZero} style={{ margin: '0px -24px 0px 64px' }} />
                            <img src={imgOne} style={{ position: 'absolute', zIndex: 1 }} />
                            <Box className={classes.futureBox} style={{ maxWidth: '154px' }}>
                                High potential for returns
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', zIndex: 1, top: '-37px' }}>
                            <Box style={{ marginLeft: '100px' }}>
                                <img src={imgZero} style={{ margin: '0px -24px 0px 24px' }} />
                                <img src={imgTwo} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '179px' }}>
                                    Early access to innovative ideas
                                </Box>
                            </Box>
                            <Box style={{ marginRight: '100px'}}>
                                <img src={imgZero} style={{ position: 'relative', left: '96px' }} />
                                <img src={imgFour} style={{ position: 'absolute', zIndex: 1, right: '133px' }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '156px' }}>
                                    Portfolio Diversification
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', zIndex: 1, marginTop: '161px' }}>
                            <Box style={{ marginLeft: '66px'}}>
                                <img src={imgZero} style={{ margin: '0px -24px 0px 24px' }} />
                                <img src={imgThree} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '146px' }}>
                                    Value beyond capital
                                </Box>
                            </Box>
                            <Box>
                                <img src={imgZero} style={{ position: 'relative', left: '232px' }} />
                                <img src={imgFive} style={{ position: 'absolute', zIndex: 1, right: '34px' }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '289px' }}>
                                    Active participation in the entrepreneurial ecosystem
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.imageContainer}>
                            <img src={frameShape} className={classes.frameImg} />
                            <img src={navLogo} className={classes.logoImg} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container style={{ display: "flex", justifyContent: "center", padding: '40px 0px', margin: '80px 0px' }}>
                    <Grid container style={{ maxWidth: '1169px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
                        <Typography style={{ fontSize: '48px', fontFamily: 'Lato', fontWeight: 600 }}>
                            Why directly invest in <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>Startups</span>?
                        </Typography>
                        <Grid item style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={GroupArrowBig} alt="" style={{ width: '100%', maxWidth: '800px' }} />
                            <Box style={{ display: 'flex', gap: '32px' }}>
                                <Box className={classes.startupBox} style={{top:"10px"}}>
                                    <Typography style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 800, color: 'rgba(0, 105, 188, 1)' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[0]?.sub_title}
                                    </Typography>
                                    <Typography style={{ wordWrap: 'break-word', fontSize: '18px', fontFamily: 'Lato', fontWeight: 400 }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[0]?.content}
                                    </Typography>
                                </Box>
                                <Box className={classes.startupBox} style={{ background: 'rgba(0, 105, 188, 1)', top: '-170px' }}>
                                    <Typography style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 800, color: 'rgba(255, 255, 255, 1)' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[1]?.sub_title}
                                    </Typography>
                                    <Typography style={{ fontSize: '18px', fontFamily: 'Lato', wordWrap: 'break-word', fontWeight: 400, color: '#FFFFFF' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[1]?.content}
                                    </Typography>
                                </Box>
                                <Box className={classes.startupBox} style={{ top: '-330px' }}>
                                    <Typography style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 800, color: 'rgba(0, 105, 188, 1)' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[2]?.sub_title}
                                    </Typography>
                                    <Typography style={{ fontSize: '18px', fontFamily: 'Lato', fontWeight: 400, wordWrap: 'break-word' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[2]?.content}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {feeStructureData != undefined ?
                    <Grid container style={{ display: "flex", justifyContent: "center", padding: '40px 0px', margin: '80px 0px' }}>
                        <Grid container style={{ maxWidth: '1169px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
                            <Typography style={{ fontSize: '48px', fontFamily: 'Lato', fontWeight: 700 }}>
                                Simplified <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 700 }}>Fee Structure</span>
                            </Typography>

                            <Grid container style={{ width: '100%', maxHeight: '248px',margin:"96px 0px 36px" }}>
                                
                                <Grid item xs={6} style={{ position: 'relative', display: 'flex', flexDirection: 'column', paddingRight: '15px' }}>
                                    <div style={{ position: 'absolute', top: "-77px", left: "32px", display: 'flex' }}>
                                        <img
                                            src={imgBigZero}
                                            className={classes.Imgnumbers}
                                            style={{ clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)', zIndex: 1 }}
                                        />
                                        <img src={imgBigOne} className={classes.Imgnumbers} style={{ marginLeft: '-12px', zIndex: 2 }} />
                                    </div>
                                    <Box className={classes.FundBox}>
                                    <Typography style={{ fontSize: '32px',padding:"32px" ,fontFamily: 'Lato', fontWeight: 600, backgroundColor: 'rgb(242, 248, 252)' }}>
                                        Invest without <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>upfront fees</span> - pay only when you invest.
                                    </Typography>
                                    </Box>

                                </Grid>
                                 
                                 <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
                                 <Typography style={{ fontSize: '18px', fontFamily: 'Lato', fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: feeStructureData[0]?.attributes?.content }}></Typography>
                                
                                </Grid>
                            </Grid>
                            
                             <Grid container style={{ width: '100%', maxHeight: '248px' ,marginTop:"20px"}}>
                                
                                 <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' , paddingRight:"15px"}}>
                                 <Typography style={{ fontSize: '18px', fontFamily: 'Lato', fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: feeStructureData[1]?.attributes?.content }}></Typography>
                                
                                </Grid>
                              
                                <Grid item xs={6} style={{ position: 'relative', display: 'flex', flexDirection: 'column', paddingLeft: '15px' }}>
                                    <div style={{ position: 'absolute', top: "-77px", left: "45px", display: 'flex' }}>
                                        <img
                                            src={imgBigZero}
                                            className={classes.Imgnumbers}
                                            style={{ clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)', zIndex: 1 }}
                                        />
                                        <img src={imgBigTwo} className={classes.Imgnumbers} style={{ marginLeft: '-10px', zIndex: 2 }} />
                                    </div>
                                    <Box className={classes.FundBox} style={{height:"296px",fontSize: '32px' ,fontFamily: 'Lato', fontWeight: 600,}}>
                                    <Typography style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 600, padding: '32px' }}>
                                        <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>Transparent fees</span> for informed investments - know the fees before you invest.
                                    </Typography>
                                    </Box>

                                </Grid>
                                
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    : ''}

                <Grid container style={{ display: "flex", justifyContent: "center", padding: '40px 0px', margin: '80px 0px' }}>
                    <Grid container style={{ maxWidth: '1169px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
                        <Typography style={{ fontSize: '18px', fontFamily: 'Lato', color: 'rgba(0, 105, 188, 1)', fontWeight: 400 }}>
                            From Our Past Successes to Your Future Opportunities
                        </Typography>
                        <Typography style={{ fontSize: '40px', fontFamily: 'Lato', fontWeight: 800 }}>
                            Our Direct Startup Investments
                        </Typography>
                        <Grid style={{ display: 'flex', gap: '30px', flexWrap: 'wrap' }}>
                            {opportunityData?.slice(0, 2).map((item: any) => (
                                <Grid style={{ display: 'flex', flexDirection: 'column', padding: '5px',border:'1px solid rgba(111, 111, 112, 1)', borderRadius:'12px',backgroundColor:'rgb(242, 248, 252)'}}>
                                    <Grid style={{ display: 'flex', gap: '20px', flexDirection: 'column', minWidth: '266px', padding: '17px' }}>
                                        <Typography style={{ fontFamily: 'Lato', fontWeight: 800, fontSize: '35px', }}>{item.attributes.company.data[0]?.attributes.company_name}</Typography>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: 'rgba(111, 111, 112, 1)', textAlign: "justify", maxWidth: "522px" }}>{item.attributes.company.data[0]?.attributes.about}</Typography>
                                    </Grid>
                                    <Grid style={{ display: 'flex', flexDirection: 'column', gap: '24px', height: '228px', padding: '17px', width: '522px' }}>

                                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Minimum Investment</Typography>
                                                <Typography className={classes.textStyle} >${this.numberWithCommas(item.attributes.minimum_investment)}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Allocation</Typography>
                                                <Typography className={classes.textStyle}>${this.numberWithCommas(item.attributes.allocation)}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}  >Round</Typography>
                                                <Typography className={classes.textStyle}>{item.attributes.round}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Estimated Fees</Typography>
                                                <Typography className={classes.textStyle}>{item.attributes.estimated_fee}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '8px' }}>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '18px' }}>View Fund Info</Typography>
                                            <Grid style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 105, 188, 1)', borderRadius: '50%', padding: '6px', color: 'white' }} onClick={() => window.location.href = `/OpportunitiesDetailsWeb/${item.id}`}>
                                                <ArrowForwardIcon />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            ))}
                        </Grid>
                        <Button className={classes.investBtn} onClick={this.startupInvestmentDetails} data-test-id="investNowBtn">Invest Now</Button>
                    </Grid>
                </Grid>

                <Footer navigation={this.props.navigation}/>
            </Paper >
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(StartupInvestmentWeb);
// Customizable Area End
