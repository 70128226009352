import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import { Box, Typography } from "@material-ui/core";
import { imgError, imgVerified } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import Loader from "./Loader.web";

interface myProps {
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    messageBox: {
        boxShadow: '0px 0px 4px 0px #00000040',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '321px',
        maxWidth: '570px',
        width: '100%',
        position: 'relative'
    }
}));

export default function EmailVerification(props: myProps) {

    const classes = useStyles();
    const [verify, setVerify] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const isEmailVerified = urlParams.get('verify');
        if (isEmailVerified) {
            setVerify(true);
            setLoading(false);
        } else {
            setVerify(false);
            setLoading(false);
        }
    }, []);

    return (
        <Paper className={classes.root}>
            <Loader loading={loading} />
            <Box className={classes.messageBox}>
                <CloseIcon style={{ cursor: 'pointer', marginBottom: '10px', top: '32px', right: '32px', position: 'absolute' }} onClick={() => window.history.back()} />
                <img src={verify ? imgVerified : imgError} />
                <Typography style={{ fontSize: '32px', fontWeight: 700, color: '#222222', textAlign: 'center' }}>
                    {verify ? 'Email address verified!' : 'Error!'}
                </Typography>
                <Typography style={{ fontSize: '16px', color: '#6F6F70', textAlign: 'center' }}>
                    {verify ? 'Your email address has been verified successfully.' : 'Your email address could not be verified.'}
                </Typography>
            </Box>
        </Paper>
    )
}
