import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showLoading: boolean;
    token: any;
    kycStatus: any,
    opportunityData: any;
    currentPage: number;
    totalPages: number;
    searchValue: string;
    investmentType: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class InvestmentOpportunitiesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getInvestmentOpportunitiesDetailsApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            opportunityData: [],
            token: '',
            kycStatus: '',
            showLoading: false,
            currentPage: 1,
            totalPages: 1,
            searchValue: '',
            investmentType: ''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getInvestmentOpportunitiesDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        opportunityData: responseJson.data,
                        totalPages: Math.ceil(responseJson.meta.total_count / 5)
                    })
                }
                this.setState({
                    showLoading:true
                })
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        this.setState({
            token: await getStorageData("token"),
            kycStatus: await getStorageData("kyc_status")
        })
        const type = this.props.navigation.getParam('type');
        this.setState({
            investmentType: type === "all" ? '' : type
        }, () => {
            this.getInvestmentOpportunitiesDetails(this.state.investmentType);
        })
        // Customizable Area End
    }

    // Customizable Area Start

    handlePageChange = (event: any, value: number) => {
        this.setState({
            currentPage: value
        }, () => this.getInvestmentOpportunitiesDetails(this.state.investmentType))
    }

    handleSearch = (event: any) => {
        this.setState({
            searchValue: event.target.value,
            currentPage:1
        })
    }


    onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this.getInvestmentOpportunitiesDetails(this.state.investmentType);
        }
    }

    getInvestmentOpportunitiesDetails = async (type: string) => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getInvestmentOpportunitiesDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/opportunities?type=${type}&search=${this.state.searchValue}&per_page=${5}&page=${this.state.currentPage}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    numberWithComma = (number: any) => {
        if(number){
            const data = number.toString().split('.');        
            data[0] = data[0].replace(new RegExp(configJSON.commaExpression, 'g'), '$&,');
            return data.join('.');
        }
    }

    redirectToInvest = async () => {
        const navigateToInvest: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigateToInvest.addData(getName(MessageEnum.NavigationTargetMessage), "InvestorProfile");
        navigateToInvest.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateToInvest);
        if (this.state.kycStatus === "filled") {
            await setStorageData("supportContact", 'showUnableToVerify');
        }
    }
    // Customizable Area End
}
