import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    startupInvestData: any;
    liquidityData: any;
    startupMarketData: any;
    indianStatupData: any;
    secondaryCardData: any;
    earlyOpportunityData: any;
    opportunityData: any;
    secondaryCompanyData : any;
    secondaryMarketData: any;
    feeStructureData : any;
    token: any;
    activeIndex: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class StartupInvestmentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getStartupInvestmentDetailsApiCallId: string = '';
    getSeconadaryLiquidityDetailsApiCallId: string = '';
    getSeconadaryStartupDetailsApiCallId: string = '';
    getIndianSeconadaryStartupDetailsApiCallId: string = '';
    getSecondaryCardDetailsApiCallId: string = '';
    getEarlyOpportunitiesDetailsApiCallId: string = '';
    getOpportunitiesDetailsApiCallId: string = '';
    getSeconadaryMarketDetailsApiCallId: string = '';
    getInvestmentSecondariesDetailsApiCallId : string = '';
    getFeeStructuresApiCallId : string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            loading: false,
            startupInvestData: [],
            liquidityData: [],
            startupMarketData: [],
            indianStatupData: [],
            secondaryCardData: [],
            earlyOpportunityData: [],
            opportunityData: [],
            secondaryCompanyData :[],
            feeStructureData : [],
            secondaryMarketData: [],
            token: '',
            activeIndex: 0
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getIndianSeconadaryStartupDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        indianStatupData: responseJson.data,
                    })
                }
            } else if (apiRequestCallId === this.getStartupInvestmentDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        startupInvestData: responseJson.data?.attributes,
                    })
                }
            } else if (apiRequestCallId === this.getOpportunitiesDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        opportunityData: responseJson.data,
                    })
                }
            } else if (apiRequestCallId === this.getSeconadaryLiquidityDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        liquidityData: responseJson.data,
                    })
                }
            } else if (apiRequestCallId === this.getSecondaryCardDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        secondaryCardData: responseJson.data,
                    })
                }
            } else if (apiRequestCallId === this.getEarlyOpportunitiesDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        earlyOpportunityData: responseJson.data,
                    })
                }
            } else if (apiRequestCallId === this.getSeconadaryStartupDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        startupMarketData: responseJson.data,
                    })
                }
            } else if (apiRequestCallId === this.getSeconadaryMarketDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        secondaryMarketData: responseJson.data,
                    })
                }
            }
            else if (apiRequestCallId === this.getInvestmentSecondariesDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        secondaryCompanyData: responseJson.data,
                    })
                }
            }
            else if (apiRequestCallId === this.getFeeStructuresApiCallId) {
                if (responseJson) {
                    this.setState({
                        feeStructureData: responseJson?.data,
                    })
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getStartupInvestmentDetails();
        this.getSeconadaryStartupDetails();
        this.getSeconadaryLiquidityDetails();
        this.getIndianSeconadaryStartupDetails();
        this.getSecondaryCardDetails();
        this.getEarlyOpportunitiesDetails();
        this.getOpportunitiesDetails();
        this.getSeconadaryMarketDetails();
        this.getInvestmentOpportunitiesDetails();
        this.getFeeStructuresDetails();
        this.setState({
            token: await getStorageData("token"),
        })
        if (typeof window !== 'undefined') {
        window.addEventListener('scroll', this.handleScroll);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleScroll = () => {
        const winHeight = window.innerHeight;
        const topLimit = winHeight * 0.2;
        const bottomLimit = winHeight * 0.8;

        let activeIndex = this.state.activeIndex;
        document.querySelectorAll('.boxBg').forEach((item, index) => {
            const element = item as HTMLElement;
            const thisTop = element.getBoundingClientRect().top;
            if (thisTop >= topLimit && thisTop + element.offsetHeight <= bottomLimit) {
                activeIndex = index;
            }
        });

        this.setState({ activeIndex });
    };

    navigateToInvest = async () => {
        if (await getStorageData("token") === null) {
            this.navigateToSignUp("EmailAccountRegistrationWeb")
        } else {
            this.props.navigation.navigate("InvestmentOpportunitiesWeb", {
                type: 'secondaries'
            });
        }
    }

    navigateToSignUp = (navigationPath: string) => {
        const goToSignUpBlock: Message = new Message(getName(MessageEnum.NavigationMessage));
        goToSignUpBlock.addData(getName(MessageEnum.NavigationTargetMessage), navigationPath);
        goToSignUpBlock.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(goToSignUpBlock);
    }

    navigateToLiquidate = async () => {
        await getStorageData("token") === null ? this.props.navigation.navigate("EmailAccountRegistrationWeb") : this.props.navigation.navigate("SecondariesInvestmentLiquidityWeb")
    }

    startupInvestmentDetails = async () =>{
        if (await getStorageData("token") === null) {
            this.navigateToSignUp("EmailAccountRegistrationWeb")
        } else {
            this.props.navigation.navigate("InvestmentOpportunitiesWeb", {
                type: 'direct_startup_investment'
            });
        }
    }

    showFundInvestmentDetails = async () =>{
        if (await getStorageData("token") === null) {
            this.navigateToSignUp("EmailAccountRegistrationWeb")
        } else {
            this.props.navigation.navigate("InvestmentOpportunitiesWeb", {
                type: 'fund_investment'
            });
        }
    }

    getSeconadaryLiquidityDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSeconadaryLiquidityDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_proposalgeneration2/secondary_liquidities");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSeconadaryStartupDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSeconadaryStartupDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_proposalgeneration2/secondary_markets");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSeconadaryMarketDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSeconadaryMarketDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_proposalgeneration2/venture_capitals?content_type=secondary_market");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getStartupInvestmentDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getStartupInvestmentDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/sections");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getIndianSeconadaryStartupDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getIndianSeconadaryStartupDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_proposalgeneration2/venture_capitals?content_type=startup_secondary");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getOpportunitiesDetails = async () => {
        const type = window.location.pathname === "/FundInvestmentWeb" ? 'fund_investment' : 'direct_startup_investment'
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getOpportunitiesDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/opportunities?type=${type}` );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getEarlyOpportunitiesDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getEarlyOpportunitiesDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_proposalgeneration2/venture_capitals?content_type=get_in_early");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSecondaryCardDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSecondaryCardDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_proposalgeneration2/venture_capitals/web");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getInvestmentOpportunitiesDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getInvestmentSecondariesDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/opportunities?type=secondaries" );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getFeeStructuresDetails = async () => {

        const type = window.location.pathname === "/FundInvestmentWeb" ? 'fund_investment' : 'direct_startup'
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getFeeStructuresApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/fee_structures?content_type=${type}` );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    numberWithCommas = (number: any) => {
        if (number) {
            const parts = number.toString().split('.');
            parts[0] = parts[0].replace(new RegExp(configJSON.commaExpression, 'g'), '$&,');
            return parts.join('.');
        }
    }
    // fund_investment
    // Customizable Area End
}
