export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgGoogleLogo = require("../assets/google_logo.png");
export const imgLeftSignupScreen = require("../assets/signUpScreen.png");
export const imgCheckbox = require("../assets/checkbox.png");
export const imgBox = require("../assets/box.png");
export const imgLogo = require("../assets/group_logo.png");
export const imgFirm = require("../assets/image_firm.png");
export const imgIndividual = require("../assets/image_individual.png");
export const imgOffice = require("../assets/image_office.png");
export const imgTrust = require("../assets/image_trust.png");
export const imgShape = require("../assets/image_shape.png");
export const imgInfo = require("../assets/image_information.png");
export const imgBlueUncheck = require("../assets/image_uncheckedbox.png");
export const imgDarkUncheck = require("../assets/dark_uncheck.png");
export const imgBlueCheck = require("../assets/blueCheck.png");
export const imgProgress = require("../assets/image_inprogress.png");
export const imgSuccess = require("../assets/check-mark 1.png");
export const imgFailed = require("../assets/exclamation 1.png");
export const imgVerify = require("../assets/image_verify.png");
