import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData, removeStorageData} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    opportunityDetails: any;
    wireInstructionsDetails: any;
    viewButton: boolean;
    opportunityID: any
    allocationAmountOpen: boolean;
    amount: string;
    amountError: string;
    selectedAmount: string;
    wireTransferName: string;
    wireTransferEmail: string;
    wireAmount: string;
    loading: boolean;
    selectedID: string;
    reviewDocumentOpen: boolean;
    checkBox: boolean;
    wireTransferOpen: boolean;
    wireInitiatedSuccess: boolean;
    wireInstructionOpen: boolean;
    wireTransferPageError: any;
    reviewDocumentError: any;
    esopLetter: any;
    joinWaitlistBox: boolean;
    fundingId: any;
    joinamount: any;
    kycStatus: any,
    successJoinList: boolean;
    docuSignURL: any;
    isLoading: boolean;
    isFocused: boolean;
    joinWaitlistSecondaryBox: boolean;
    emailAddress: string;
    emailError: string;
    joinWaitlistEmail: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class OpportunitiesDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getInvestmentOpportunitiesDetailsByIDApiCallId: string = '';
    submitWaitingListApiCallId: string = '';
    getWireInstructionsListByIDApiCallId: string = '';
    submitWireTransferListApiCallId: string = '';
    submitJointWaitingListApiCallId: string = '';
    getdocuSignByIDApiCallId: string = '';
    secondaryWaitingListApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            opportunityDetails: {},
            wireInstructionsDetails: {},
            viewButton: true,
            opportunityID: '',
            allocationAmountOpen: false,
            loading: false,
            amount: '',
            amountError: '',
            selectedAmount: '',
            selectedID: '',
            wireTransferName: '',
            wireTransferEmail: '',
            wireAmount: '',
            reviewDocumentOpen: false,
            checkBox: false,
            esopLetter: null,
            wireTransferOpen: false,
            wireInitiatedSuccess: false,
            wireInstructionOpen: false,
            wireTransferPageError: {
                wireTransferName: '',
                wireTransferEmail: '',
                wireAmount: '',
                esopLetter: null,
            },
            reviewDocumentError: {
                checkBox: ''
            },
            fundingId: '',
            joinWaitlistBox: false,
            joinamount: '',
            kycStatus: '',
            successJoinList: false,
            docuSignURL: {},
            isLoading: false,
            isFocused:false,
            joinWaitlistSecondaryBox: false,
            emailAddress: '',
            emailError: '',
            joinWaitlistEmail:''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getInvestmentOpportunitiesDetailsByIDApiCallId) {
                if (responseJson) {
                    let getInvestorsData = responseJson.data?.attributes?.co_investors?.data;
                    let updatedOpportunityDetails = { ...responseJson.data };

                    if (getInvestorsData.length > 0 && getInvestorsData.length <= 5) {
                        while (getInvestorsData.length <= 6) {
                            getInvestorsData.push(...getInvestorsData);
                        }
                        updatedOpportunityDetails.attributes.co_investors.data = getInvestorsData;
                    }
                
                    this.setState({
                        opportunityDetails: updatedOpportunityDetails,
                    })
                }
            }
            else if (apiRequestCallId === this.submitWaitingListApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        allocationAmountOpen: false,
                        reviewDocumentOpen: true,
                        fundingId: responseJson.data.id,
                        wireAmount: this.state.amount
                    })
                    let updatedData = {
                        fundingId: this.state.fundingId,
                        wireAmount: this.state.wireAmount
                    }
                    await setStorageData("wireTransfer", JSON.stringify(updatedData));
                } else if (responseJson.errors) {
                    this.setState({
                        allocationAmountOpen: false,
                    })
                    toast.error(responseJson.errors[0])
                }
            }
            else if (apiRequestCallId === this.getWireInstructionsListByIDApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        wireInstructionsDetails: responseJson.data,
                    })
                }
            }
            else if (apiRequestCallId === this.submitWireTransferListApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        wireTransferOpen: false,
                        wireInitiatedSuccess: true,
                    })
                    this.props.navigation.navigate("OpportunitiesDetailsWeb", {
                        id: this.state.opportunityID,
                    });
                }
            }
            else if (apiRequestCallId === this.submitJointWaitingListApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        joinWaitlistBox: false,
                        successJoinList: true,
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        joinWaitlistBox: false,
                    })
                    toast.error(responseJson.errors[0])
                }
            }
            else if (apiRequestCallId === this.getdocuSignByIDApiCallId) {
                this.setState({
                    isLoading: false
                })
                if (responseJson) {
                    window.location.href = responseJson.signing_url.url;
                }
            }
            else if (apiRequestCallId === this.secondaryWaitingListApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        joinWaitlistSecondaryBox: false,
                        loading:false
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        joinWaitlistSecondaryBox: false,
                        loading:false
                    })
                    toast.error(responseJson.errors[0])
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const params = new URLSearchParams(window.location.search);
        let queryParams = params.get('docuSign');
        if (queryParams == 'wire_transfer') {
            const wireTransferData = JSON.parse(await getStorageData("wireTransfer"))
            if (wireTransferData) {
                this.setState({
                    wireAmount: wireTransferData.wireAmount,
                    fundingId: wireTransferData.fundingId
                })
            }
            this.setState({
                reviewDocumentOpen: false,
                wireTransferOpen: true,
                isLoading: false
            })
        }
        let userInfo = await getStorageData("userInfo")
        let userProfile = JSON.parse(userInfo)
        if (userProfile) {
            let name = userProfile.attributes.first_name + ' ' + userProfile.attributes.last_name
            let email = userProfile.attributes.email
            this.setState({
                wireTransferName: name,
                wireTransferEmail: email,
                joinWaitlistEmail: email
            })
        }
        this.setState({
            kycStatus: await getStorageData("kyc_status"),
        })

        const id = this.props.navigation.getParam('id');
        this.setState({
            opportunityID: id
        }, async () => {
            this.getInvestmentOpportunitiesDetailsByID(id);
            const reviewDocuments = JSON.parse(await getStorageData("terms_and_provisions"))
            if (reviewDocuments?.showReviewPopup) {
                this.setState({
                    allocationAmountOpen: false,
                    reviewDocumentOpen: true,
                    amount: reviewDocuments.amount,
                    selectedID: id
                })
                removeStorageData("terms_and_provisions")
            }
            
            this.handleSubmit()
        })
        this.getWireInstructionsList();
        // Customizable Area End
    }

    // Customizable Area Start
    showDetails = () => {
        this.setState({
            viewButton: false
        })
    }

    navigateToContact = async () => {
        const navigateToContact: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigateToContact.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
        navigateToContact.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateToContact);
        await setStorageData("contactUs", 'showContactUs');
    }

    handlestartupNameChange = (event: any) => {
        this.setState({
            wireTransferEmail: event.target.value,
            wireTransferPageError: {
                wireTransferEmail: ''
            }
        })
    }

    handleWebsiteChange = (event: any) => {
        this.setState({
            wireAmount: event.target.value,
            wireTransferPageError: {
                wireAmount: ''
            }
        })
    }
    
    handleNameChange = (event: any) => {
        this.setState({
            wireTransferName: event.target.value,
            wireTransferPageError: {
                wireTransferName: ''
            }
        })
    }

    reviewDocumentSignError = () => {
        const { checkBox } = this.state;
        if (!checkBox) {
            this.setState((prevState) => ({
                reviewDocumentError: {
                    ...prevState.reviewDocumentError,
                    checkBox: 'Terms and Provisions is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                reviewDocumentError: {
                    ...prevState.reviewDocumentError,
                    checkBox: ''
                },
            }));
        }
        return true;
    }

    handleRemoveESOPDocFile = () => {
        this.setState({ esopLetter: null });
    }

    handleAllocationAmountOpen = (id: any) => {
        this.setState({
            allocationAmountOpen: true,
            selectedID: id,
            amount: '',
            amountError: ''
        });
    };

    handleJoinWaitlistPopUp = () => {
        if (this.state.opportunityDetails?.attributes?.company?.data[0]?.attributes?.company_type == 'secondaries') {
            this.setState({
                joinWaitlistSecondaryBox: true,
                emailAddress:'',
                emailError:''
            })
        } else {
            this.setState({
                joinWaitlistBox: true,
                joinamount: ''
            })
        }
    }

    wireTransferError = () => {
        const { wireTransferName, wireAmount, wireTransferEmail, esopLetter } = this.state;
        if (!wireTransferName) {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    wireTransferName: 'Legal Name is required'
                },
            }));
            return false;
        } else if (wireTransferName.length > 30) {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    wireTransferName: 'Legal Name should not exceed 30 characters'
                },
            }));
            return false;
        }
        else {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    wireTransferName: ''
                },
            }));
        }

        if (!wireTransferEmail) {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    wireTransferEmail: 'Email is required'
                },
            }));
            return false;
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(wireTransferEmail)) {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    wireTransferEmail: 'Invalid email address'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    wireTransferEmail: ''
                },
            }));
        }

        if (!wireAmount) {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    wireAmount: 'Amount is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    wireAmount: ''
                },
            }));
        }

        if (!esopLetter) {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    esopLetter: 'Proof of Transfer is required'
                },
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                wireTransferPageError: {
                    ...prevState.wireTransferPageError,
                    esopLetter: ''
                },
            }));
        }

        return true;
    };

    uploadDoc = (event: any) => {
        const file = event.target.files[0];
        this.setState({
            esopLetter: file,
            wireTransferPageError: {
                esopLetter: ''
            }
        })
    }
    
    validateAmount = () => {
        const { amount } = this.state;
        let updatedAmount =  amount.toString().replace(/[$,]/g, '')
        if (Number(updatedAmount) < 100000) {
            this.setState({
                amountError: 'The entered amount is below the minimum investment value. Amount must be in the range of $100K - 1M.'
            })
            return false;
        } else if (Number(updatedAmount) > 1000000) {
            this.setState({
                amountError: 'The entered amount crosses the maximum investment value. Amount must be in the range of $100K - 1M.'
            })
            return false;
        } else {
            this.setState({
                amountError: ''
            })
            return true;
        }
    }

    handleAllocationAmountClose = () => {
        this.setState({
            allocationAmountOpen: false,
            wireTransferOpen: false,
            reviewDocumentOpen: false,
            wireInitiatedSuccess: false,
            wireInstructionOpen: false,
            amount: '',
            selectedAmount: '',
            wireTransferEmail: '',
            wireTransferName: '',
            wireAmount: '',
            esopLetter: null,
            checkBox: false,
        })
    };

    wireInstructions = () => {
        this.setState({
            wireInstructionOpen: true,
            wireTransferOpen: false,
        })
    }

    handleReviewDocumentOpen = () => {
        this.setState({
            reviewDocumentOpen: true,
        });
    };

    handleCheckboxChange = () => {
        this.setState({
            checkBox: !this.state.checkBox,
            reviewDocumentError: {
                checkBox: ''
            }
        })
    }
    
    handleWireTransferOpen = () => {
        this.setState({
            wireTransferOpen: true,
        });
    };


    handleReviewDocumentClose = () => {
        this.setState({
            allocationAmountOpen: true,
            reviewDocumentOpen: false,
            wireInstructionOpen: false,
            wireTransferOpen: false,
        })
    };

    backToWireTransfer = () => {
        this.setState({
            reviewDocumentOpen: true,
            allocationAmountOpen: false,
            wireInstructionOpen: false,
            wireTransferOpen: false,
        })
    }

    backToReviewDocument = () => {
        this.setState({
            reviewDocumentOpen: true,
            allocationAmountOpen: false,
            wireInstructionOpen: false,
            wireTransferOpen: false,
        })
    }

    backTopWireInstructions = () => {
        this.setState({
            wireTransferOpen: true,
            reviewDocumentOpen: false,
            allocationAmountOpen: false,
            wireInstructionOpen: false,
        })
    }


    handleReviewDocumentDone = () => {
        if (this.reviewDocumentSignError()) {
            this.setState({
                // wireTransferOpen: true,
                // reviewDocumentOpen: false,
                isLoading: true
            })
            this.docuSign();
        }
    }

    WireInitiatedSuccessPopUp = () => {
        if (this.wireTransferError()) {
            this.submitWireTransferList();
        }
    }

    handleAmountChange = (event: any) => {
        this.setState({
            amount: event.target.value,
            amountError: ''
        })
    }

    handleSelectedAmount = (text: any) => {
        this.setState({
            selectedAmount: text,
            amount: text,
            amountError: ''
        })
    }


    handleJoinAmountChange = (event: any) => {
        this.setState({
            joinamount: event.target.value,
        })
    }

    navigateToInvestList = () => {
        window.location.href = "/InvestmentOpportunitiesWeb/all"
    }


    handleSubmit = () => {
        this.validateAmount()
        if (this.validateAmount()) {
            this.submitWaitingList();
        }
    }

    handleSubmitJoinWaitList = () => {
        const updatedJoinamount = this.state.joinamount.toString().replace(/[$,]/g, '')
        if (updatedJoinamount == '' || updatedJoinamount >= 100000) {
            this.submitJoinWaitingList();
        }
    }

    handleJoinListCancel = () => {
        this.setState({
            joinWaitlistBox: false,
            joinamount: ''
        })
    }

    handleSuccessJoinBox = () => {
        this.setState({
            successJoinList: false,
        })
    }

    getInvestmentOpportunitiesDetailsByID = async (id: any) => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getInvestmentOpportunitiesDetailsByIDApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/opportunities/" + id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    submitWaitingList = async () => {
        this.setState({ loading: true });

        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        let updatedAmount =  this.state.amount.toString().replace(/[$,]/g, '')
        const data = {
            "attributes": {
                "opportunity_id": this.state.selectedID,
                "amount": updatedAmount
            }
        }
        const httpBody = { data: data };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.submitWaitingListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/investments");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    submitWireTransferList = async () => {
        this.setState({ loading: true });

        const { wireTransferName, wireAmount, wireTransferEmail, esopLetter, fundingId } = this.state;
        const updatedWireAmount = wireAmount.toString().replace(/[$,]/g, '')
        const formData = new FormData();
        formData.append('data[attributes][legal_name]', wireTransferName);
        formData.append('data[attributes][funding_amount]', updatedWireAmount);
        formData.append('data[attributes][email]', wireTransferEmail);
        formData.append('data[attributes][proof]', esopLetter);
        const header = { token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.submitWireTransferListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/investments/" + fundingId);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getWireInstructionsList = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getWireInstructionsListByIDApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_admin/wire_instructions");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    submitJoinWaitingList = async () => {
        this.setState({ loading: true });

        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const updatedJoinamount = this.state.joinamount.toString().replace(/[$,]/g, '')
        const record = {
            "attributes": {
                "opportunity_id": this.state.opportunityID,
                "amount": updatedJoinamount,
                "email": this.state.joinWaitlistEmail
            }
        }
        const httpBody = { data: record };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.submitJointWaitingListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_emaillists/opportunity_waitlists");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    docuSign = async () => {
        const checkUrl = window.location.href.split('?')
        const currentUrl = checkUrl[0] + '?docuSign=wire_transfer';
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getdocuSignByIDApiCallId = requestMessage.messageId;

        const data = {
            "attributes": {
                "investment_id": this.state.fundingId,
                "return_url": currentUrl
            }
        }
        const httpBody = { data: data };
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.docuSignEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    numberWithCommaOpportunity = (number: any) => {
        if (number) {
            const datas = number.toString()?.split('.');
            datas[0] = datas[0].replace(new RegExp(configJSON.commaExpression, 'g'), '$&,');
            return datas.join('.');
        }
    }

    completeDeclaration = async () => {
        const navigateToKyc: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigateToKyc.addData(getName(MessageEnum.NavigationTargetMessage), "InvestorProfile");
        navigateToKyc.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateToKyc);
        if (this.state.kycStatus === "filled") {
            await setStorageData("supportContact", 'showUnableToVerify');
        }
    }

    redirectToTermAndCond = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        let updatedData = {
            amount: this.state.amount,
            showReviewPopup: true
        }
        await setStorageData("terms_and_provisions", JSON.stringify(updatedData));
        this.props.navigation.navigate("TermsConditions", {
            type: 'terms_and_conditions',
        });
    }

    addBreak = (value: string) => {
        let newString = value
        if (newString) {
            newString = value.replace(/\r\n/g, "<br>")
        }
        return newString
    }

    addUnderlineBreak = (value: string) => {
        let newString = value
        if (newString) {
            const lines = value.split(/\r\n/g);
            newString = lines
                .map((line, index) => {
                    if (index === 0) {
                        return line;
                    } else {
                        return `<span style="border-bottom: 1px solid #222;">${line}</span>`;
                    }
                })
                .join("<br>");
        }
        return newString
    }

    validateEmails = () => {
        const { emailAddress } = this.state;
        if (!emailAddress) {
            this.setState({ emailError: "Email is required" });
            return false;
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailAddress)) {
            this.setState({ emailError: "Invalid email address" });
            return false;
        }
        this.setState({ emailError: "" });
        return true;
    };

    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            emailAddress: event.target.value
        })
    }

    handleSecondaryWaitList = async () => {
        const isEmailValid = this.validateEmails();
        if (!isEmailValid) {
            return;
        } else {
            this.setState({ loading: true });
            const header = { "Content-Type": "application/json", token: await getStorageData("token") };
            const data = {
                "attributes": {
                    "opportunity_id": this.state.opportunityID,
                    "email": this.state.emailAddress,
                }
            }
            const httpBody = { data: data };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.secondaryWaitingListApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.secondaryWaitList);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    // Customizable Area End
}
