import React from "react";

// Customizable Area Start
import {
    Box,
    Paper,
    Typography,
    OutlinedInput,
    Button,
    FormControlLabel,
    Checkbox,
    Container,
    FormHelperText,
    Select,
    MenuItem,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import { buttons, checkMark, imageButtonClose, imageShape, pdfImage, textInput,defaultImage, jpgImage, pngImage, zipImage, jpegImage } from "./assets";
import TopNavBar from "../../../components/src/TopNavbar.web";
import Footer from "../../../components/src/Footer.web";
import { NumericFormat } from 'react-number-format';
// Customizable Area End

import SecondariesInvestmentLiquidityController, {
    Props,
} from "./SecondariesInvestmentLiquidityController.web";

const styles: StyleRules = {
    // Customizable Area Start

    containerStyle: {
        display: "flex",
        justifyContent: "center",
        margin: '72px 0px',
        padding: '40px 0px'
    },
    errorText: {
        color: "#DC2626",
        fontWeight: 400,
        fontSize: "14px",
        margin: "0px",
        width: '100%'
    },
    childContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px'
    },

    nextBtn: {
        textTransform: "none",
        backgroundColor: 'rgba(0, 105, 188, 1)',
        padding: "10px 16px",
        height: "60px",
        width: "153px",
        color: "#FFFFFF",
        borderRadius: "8px",
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: "20px",
        "&:hover": {
            backgroundColor: "rgba(0, 105, 188, 1)",
        },
        "&:disabled": {
            backgroundColor: '#D9D9D9'
        }
    },
    uploadBtn: {
        height: '48px',
        width: '506px',
        textTransform: 'capitalize',
        color: 'rgba(0, 105, 188, 1)',
        backgroundImage: `url(${textInput})`,
        border: '2px none rgba(0, 105, 188, 1)'
    },
    uploadedDoc: {
        height: '48px',
        width: '474px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontSize: '16px',
        borderRadius: '8px',
        border: '1px solid rgba(189, 189, 189, 1)',
        justifyContent: 'space-between',
        padding: '0px 16px'
    },

    checkBtn: {
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'rgba(0, 105, 188, 1)'
        }
    },
    // Customizable Area End
};

export class SecondariesInvestmentLiquidityWeb extends SecondariesInvestmentLiquidityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showingCorrectLogo = (fileContent: File | null) => {
        let previewImg = defaultImage;
        if (fileContent) {
            if (fileContent?.name?.includes('jpg')) {
                previewImg = jpgImage;
            } else if (fileContent?.name?.includes('jpeg')) {
                previewImg = jpegImage;
            } else if (fileContent?.name?.includes('png')) {
                previewImg = pngImage;
            } else if (fileContent?.name?.includes('pdf')) {
                previewImg = pdfImage;
            } else if (fileContent?.name?.includes('zip')) {
                previewImg = zipImage;
            }
        }
        return previewImg
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { afterIntroductory, introductoryProfile, afterAdditional, receiveRequest, websiteLink, startupName,
            personName, profileType, firstPageError, secondPageError, thirdPageError, currentValuation, offerValuation, qualityShare, lastThreeYearRevenue, additionalInfo, declaration, esopLetter, otherFile, shareCertificate, investmentAgreement, PreviousYearRevenue } = this.state;
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Paper style={{ minHeight: '100vh', overflowX: 'hidden' }}>
                <TopNavBar navigation={this.props.navigation} />
                <Container style={{ display: 'flex', height: '900px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {introductoryProfile &&
                        <Box style={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }} >
                            <Box style={{ position: 'absolute' }} >
                                <img src={imageShape} />
                            </Box>
                            <Box style={{ maxWidth: '520px', width: '100%', alignItems: 'center', gap: '24px', display: 'flex', flexDirection: 'column', position: 'inherit', backgroundColor: 'rgba(255, 255, 255, 0.95)', padding: '32px 0px', borderRadius: '16px', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)' }}>
                                <Box>
                                    <Typography style={{ fontSize: '32px', textAlign: 'center', fontFamily: 'Lato', fontWeight: '800' }}>Introductory Profile</Typography>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column', }}>
                                    <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>You are a</span>
                                    <Select
                                        variant="outlined"
                                        value={profileType}
                                        onChange={this.handleProfileChange}
                                        onBlur={this.validateProfile}
                                        displayEmpty
                                        style={{
                                            width: "456px",
                                            height: "48px",
                                            borderRadius: "8px",
                                            justifyContent: 'center',
                                            border: '1px solid rgba(189, 189, 189, 1)'
                                        }}
                                    >
                                        <MenuItem value='Founder'>Founder</MenuItem>
                                        <MenuItem value='Employee'>Employee</MenuItem>
                                        <MenuItem value='Investor'>Investor</MenuItem>
                                    </Select>
                                    <FormHelperText className={classes.errorText}>
                                        {firstPageError.profileType}
                                    </FormHelperText>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Name</span>
                                    <OutlinedInput
                                        style={{
                                            width: "456px",
                                            height: "48px",
                                            borderRadius: "8px",
                                            justifyContent: 'center',
                                        }}
                                        value={personName}
                                        onChange={this.handleNameChange}
                                        onBlur={this.validatePersonName}
                                        type="text"
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {firstPageError.personName}
                                    </FormHelperText>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Name of the Startup/Fund</span>
                                    <OutlinedInput
                                        style={{
                                            width: "456px",
                                            height: "48px",
                                            borderRadius: "8px",
                                            justifyContent: 'center',
                                        }}
                                        value={startupName}
                                        onChange={this.handleStartUpNameChange}
                                        type="text"
                                        onBlur={this.validateStartUpName}
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {firstPageError.startupName}
                                    </FormHelperText>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Website Link (Startup/Fund)</span>
                                    <OutlinedInput
                                        style={{
                                            width: "456px",
                                            height: "48px",
                                            borderRadius: "8px",
                                            justifyContent: 'center',
                                        }}
                                        value={websiteLink}
                                        onChange={this.handleWebLinkChange}
                                        type="text"
                                        onBlur={this.validateWebLink}
                                        data-test-id="weblinkBtn"
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {firstPageError.websiteLink}
                                    </FormHelperText>
                                </Box>
                                <Button className={classes.nextBtn} onClick={this.gotoAdditional} data-test-id="nextFirstBtn">Next</Button>

                            </Box>
                        </Box>
                    }
                    {afterIntroductory &&
                        <Box style={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }} >
                            <Box style={{ position: 'absolute' }} >
                                <img src={imageShape} />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '520px', width: '100%', gap: '24px', borderRadius: '16px', position: 'inherit', backgroundColor: 'rgba(255, 255, 255, 0.95)', padding: '32px 0px', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)' }}>
                                <Box>
                                    <Typography style={{ fontSize: '32px', textAlign: 'center', fontFamily: 'Lato', fontWeight: '800' }}>Additional Details</Typography>
                                </Box>

                                <Box style={{ display: 'flex' }}>

                                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Current Valuation</span>
                                        <NumericFormat
                                            prefix={'$'}
                                            value={currentValuation}
                                            onChange={this.handleCurrentValueChange}
                                            onBlur={this.validateCurrentValue}
                                            thousandSeparator={true}
                                            placeholder="$"
                                            style={{
                                                height: "48px",
                                                width: "216px",
                                                marginRight: '24px',
                                                borderRadius: "8px",
                                                fontSize: '16px',
                                                border: 'none',
                                                background: 'transparent',
                                                paddingLeft: '14px',
                                                outline: 'none',
                                                boxShadow: '0 0 0 1px #BDBDBD inset',
                                            }}
                                        />
                                        <FormHelperText className={classes.errorText}>
                                            {secondPageError.currentValuation}
                                        </FormHelperText>
                                    </Box>

                                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Offered Valuation</span>
                                        <NumericFormat
                                            prefix={'$'}
                                            value={offerValuation}
                                            thousandSeparator={true}
                                            onBlur={this.validateOfferValue}
                                            onChange={this.handleOfferValueChange}
                                            placeholder="$"
                                            style={{
                                                borderRadius: "8px",
                                                height: "48px",
                                                width: "216px",
                                                fontSize: '16px',
                                                border: 'none',
                                                background: 'transparent',
                                                paddingLeft: '14px',
                                                outline: 'none',
                                                boxShadow: '0 0 0 1px #BDBDBD inset',
                                            }}
                                        />
                                        <FormHelperText className={classes.errorText}>
                                            {secondPageError.offerValuation}
                                        </FormHelperText>
                                    </Box>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Quantity of shares you would like to liquidate</span>
                                    <OutlinedInput
                                        style={{
                                            width: "456px",
                                            height: "48px",
                                            borderRadius: "8px",
                                            justifyContent: 'center',
                                        }}
                                        value={qualityShare}
                                        onChange={this.handleQualityShareChange}
                                        onBlur={this.validateQualityShare}
                                        type="number"
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {secondPageError.qualityShare}
                                    </FormHelperText>
                                </Box>

                                <Box style={{ display: 'flex' }}>
                                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '14px', marginBottom: '5px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Last 3 years revenue</span>
                                        <NumericFormat
                                            data-test-id="threeYearId"
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            value={lastThreeYearRevenue}
                                            placeholder="$"
                                            onChange={this.handleThreeYearRevenueChange}
                                            onBlur={this.validateThreeYearRevenue}
                                            style={{
                                                marginRight: '24px',
                                                height: "48px",
                                                width: "216px",
                                                fontSize: '16px',
                                                borderRadius: "8px",
                                                border: 'none',
                                                background: 'transparent',
                                                outline: 'none',
                                                paddingLeft: '14px',
                                                boxShadow: '0 0 0 1px #BDBDBD inset',
                                            }}
                                        />
                                        <FormHelperText className={classes.errorText}>
                                            {secondPageError.lastThreeYearRevenue}
                                        </FormHelperText>
                                    </Box>

                                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '14px', marginBottom: '5px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Firm's revenue for the previous year</span>
                                        <NumericFormat
                                            thousandSeparator={true}
                                            placeholder="$"
                                            prefix={'$'}
                                            style={{
                                                border: 'none',
                                                height: "48px",
                                                borderRadius: "8px",
                                                fontSize: '16px',
                                                width: "216px",
                                                outline: 'none',
                                                paddingLeft: '14px',
                                                boxShadow: '0 0 0 1px #BDBDBD inset',
                                                background: 'transparent'
                                            }}
                                            onBlur={this.validatePreviousYearRevenue}
                                            value={PreviousYearRevenue}
                                            onChange={this.handlePreviousYearRevenueChange}
                                            data-test-id="previousYearId"
                                        />
                                        <FormHelperText className={classes.errorText}>
                                            {secondPageError.PreviousYearRevenue}
                                        </FormHelperText>
                                    </Box>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Additional information</span>
                                    <OutlinedInput
                                        style={{
                                            width: "456px",
                                            height: "48px",
                                            borderRadius: "8px",
                                            justifyContent: 'center',
                                        }}
                                        value={additionalInfo}
                                        onChange={this.handleAdditionalInfoChange}
                                        onBlur={this.validateAdditionalInfo}
                                        type="text"
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {secondPageError.additionalInfo}
                                    </FormHelperText>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={declaration}
                                            onClick={this.handleDeclarationChange}
                                            onBlur={this.validateDeclaration}
                                        />}
                                        className={classes.checkBtn}
                                        label={
                                            <Typography style={{ marginRight: '54px', fontSize: "14px", fontFamily: 'Roboto', color: 'rgba(15, 23, 42, 1)', fontWeight:400, lineHeight:"21px" }}>
                                                I do hereby declare that all the above information given by me is true and to the best of my knowledge and belief.
                                            </Typography>
                                        }
                                        style={{ margin: '0px 0px 0px 24px' }}
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {secondPageError.declaration}
                                    </FormHelperText>
                                </Box>
                                <Button className={classes.nextBtn} onClick={this.gotoDocument}>Next</Button>
                                <Typography style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 700, color: 'rgba(34, 34, 34, 1)', }}>Need help? {" "}
                                    <span onClick={this.navigateToSettings} style={{ color: 'rgba(0, 105, 188, 1)' }}>
                                        Contact us
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                    }

                    {afterAdditional &&
                        <Box style={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }} >
                            <Box style={{ position: 'absolute' }} >
                                <img src={imageShape} />
                            </Box>
                            <Box style={{ width: '100%', maxWidth: '520px', gap: '24px', position: 'inherit', backgroundColor: 'rgba(255, 255, 255, 0.95)', borderRadius: '16px', padding: '32px', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <Typography style={{ fontSize: '32px', textAlign: 'center', fontFamily: 'Lato', fontWeight: '800' }}>Documents</Typography>
                                <Typography style={{ fontSize: '16px', textAlign: 'center', fontFamily: 'Roboto', color: 'rgba(111, 111, 112, 1)' }}>Please share documents that will help us verify your allocation</Typography>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Investment Agreement</Typography>
                                    <Box style={{ position: 'relative' }}  >
                                        <label htmlFor="uploadInvestmentAgreementFile">
                                            <input
                                                data-test-id="uploadInvestmentAgreementFile"
                                                style={{ display: 'none' }}
                                                id="uploadInvestmentAgreementFile"
                                                name="uploadInvestmentAgreementFile"
                                                type="file"
                                                onChange={this.uploadInvestmentAgreementFile}
                                                onBlur={this.validateThirdPage}
                                            />
                                            {(investmentAgreement === null || investmentAgreement === undefined) ? (
                                                <Button
                                                    color="secondary"
                                                    className={classes.uploadBtn}
                                                    variant="outlined" component="span">
                                                    <img src={buttons} />
                                                </Button>
                                            ) : (
                                                <Box className={classes.uploadedDoc}>
                                                    <Box style={{ display: 'flex', gap: '12px' }}>
                                                        <img src={this.showingCorrectLogo(investmentAgreement)} style={{width: '24px', height: '24px'}} />
                                                        {investmentAgreement.name}
                                                    </Box>
                                                    <Button onClick={this.handleRemoveInvestmentAgreement} style={{ minWidth: '0px' }} ><img src={imageButtonClose} style={{ cursor: 'pointer' }} /></Button>
                                                </Box>
                                            )}
                                        </label>
                                        <FormHelperText className={classes.errorText}>
                                            {thirdPageError.investmentAgreement}
                                        </FormHelperText>
                                    </Box>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Share Certificate</Typography>
                                    <Box style={{ position: 'relative' }}>
                                        <label htmlFor="uploadShareCertificateFile">
                                            <input
                                                data-test-id="uploadShareCertificateFile"
                                                onBlur={this.validateThirdPage}
                                                style={{ display: 'none' }}
                                                id="uploadShareCertificateFile"
                                                name="uploadShareCertificateFile"
                                                type="file"
                                                onChange={this.uploadShareCertificateFile}
                                            />
                                            {(shareCertificate === null || shareCertificate === undefined) ? (
                                                <Button
                                                    color="secondary"
                                                    className={classes.uploadBtn}
                                                    variant="outlined" component="span">
                                                    <img src={buttons} />
                                                </Button>
                                            ) : (
                                                <Box className={classes.uploadedDoc}>
                                                    <Box style={{ display: 'flex', gap: '12px' }}>
                                                        <img src={this.showingCorrectLogo(shareCertificate)} style={{width: '24px', height: '24px'}}/>
                                                        {shareCertificate.name}
                                                    </Box>
                                                    <Button onClick={this.handleRemoveShareCertificate} style={{ minWidth: '0px' }} ><img src={imageButtonClose} style={{ cursor: 'pointer' }} /></Button>
                                                </Box>
                                            )}
                                        </label>
                                        <FormHelperText className={classes.errorText}>
                                            {thirdPageError.shareCertificate}
                                        </FormHelperText>
                                    </Box>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>ESOP Letter</Typography>
                                    <Box style={{ position: 'relative' }}>
                                        <label htmlFor="uploadESOPFile">
                                            <input
                                                style={{ display: 'none' }}
                                                id="uploadESOPFile"
                                                data-test-id="uploadESOPFile"
                                                name="uploadESOPFile"
                                                type="file"
                                                onChange={this.uploadESOPFile}
                                                onBlur={this.validateThirdPage}
                                            />
                                            {(esopLetter === null || esopLetter === undefined) ? (
                                                <Button
                                                    color="secondary"
                                                    className={classes.uploadBtn}
                                                    variant="outlined" component="span">
                                                    <img src={buttons} />
                                                </Button>
                                            ) : (
                                                <Box className={classes.uploadedDoc}>
                                                    <Box style={{ display: 'flex', gap: '12px' }}>
                                                        <img src={this.showingCorrectLogo(esopLetter)} style={{width: '24px', height: '24px'}}/>
                                                        {esopLetter.name}
                                                    </Box>
                                                    <Button onClick={this.handleRemoveESOPDoc} style={{ minWidth: '0px' }} ><img src={imageButtonClose} style={{ cursor: 'pointer' }} /></Button>
                                                </Box>
                                            )}
                                        </label>
                                        <FormHelperText className={classes.errorText}>
                                            {thirdPageError.esopLetter}
                                        </FormHelperText>
                                    </Box>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Other</Typography>
                                    <Box style={{ position: 'relative' }}>
                                        <label htmlFor="uploadOtherFile">
                                            <input
                                                style={{ display: 'none' }}
                                                id="uploadOtherFile"
                                                name="uploadOtherFile"
                                                type="file"
                                                onChange={this.uploadOtherFile}
                                                onBlur={this.validateThirdPage}
                                                data-test-id="fileUploadBtn"
                                            />
                                            {(otherFile === null || otherFile === undefined) ? (
                                                <Button
                                                    color="secondary"
                                                    className={classes.uploadBtn}
                                                    variant="outlined" component="span">
                                                    <img src={buttons} />
                                                </Button>
                                            ) : (
                                                <Box className={classes.uploadedDoc}>
                                                    <Box style={{ display: 'flex', gap: '12px' }}>
                                                        <img src={this.showingCorrectLogo(otherFile)} style={{width: '24px', height: '24px'}}/>
                                                        {otherFile.name}
                                                    </Box>
                                                    <Button onClick={this.handleRemoveOtherFile} style={{ minWidth: '0px' }} ><img src={imageButtonClose} style={{ cursor: 'pointer' }} /></Button>
                                                </Box>
                                            )}
                                        </label>
                                        <FormHelperText className={classes.errorText}>
                                            {thirdPageError.otherFile}
                                        </FormHelperText>
                                        <Typography style={{ margin: '12px 0px', fontSize: '14px', fontWeight: 400 }}>*in pdf, jpg, jpeg format only</Typography>
                                    </Box>
                                </Box>

                                <Button className={classes.nextBtn} onClick={this.handleSubmit} disabled={!this.validateThirdPage()}>Next</Button>
                                <Typography style={{ fontSize: '14px', fontFamily: 'Roboto', fontWeight: 700, color: 'rgba(0, 105, 188, 1)', }} onClick={this.navigateToSettings}>
                                    Need help? Contact us</Typography>
                            </Box>
                        </Box>
                    }

                    {receiveRequest &&
                        <Box style={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }} >
                            <Box style={{ position: 'absolute' }} >
                                <img src={imageShape} />
                            </Box>
                            <Box style={{ maxWidth: '570px', width: '100%', borderRadius: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', position: 'absolute', bottom: '-20px', backgroundColor: 'rgba(255, 255, 255, 0.95)', padding: '32px 0px' }}>
                                <img src={checkMark} style={{ height: '160px', width: '160px' }} />
                                <Typography style={{ fontSize: '32px', fontWeight: 800, fontFamily: 'Lato', color: 'rgba(34, 34, 34, 1),', textAlign: 'center' }}>Request received!</Typography>
                                <Typography style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(111, 111, 112, 1)', textAlign: 'center' }}>
                                    Your request has been received.
                                </Typography>
                                <Typography style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(111, 111, 112, 1)', textAlign: 'center' }}>
                                    We will notify you once your request is approved
                                </Typography>
                            </Box>
                        </Box>
                    }

                </Container>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(SecondariesInvestmentLiquidityWeb);
// Customizable Area End
