export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const changepassword = require("../assets/image_changepassword.png");
export const contactus = require("../assets/image_contactus.png");
export const invpref = require("../assets/image_invpref.png");
export const kyc = require("../assets/image_kyc.png");
export const logout = require("../assets/image_logout.png");
export const notifications = require("../assets/image_notifications.png");
export const profilePicture = require("../assets/image_profilepicture.png");
export const user = require("../assets/image_user .png");
export const selectedUser = require("../assets/image_user.png");
export const selectedPhone = require("../assets/phone .png");
export const selectedChangepassword = require("../assets/padlock .png");
export const selectedNotification = require("../assets/image_notifications .png");
export const selectedKyc = require("../assets/image_kyc .png");
export const selectedInvpref = require("../assets/image_invpref .png");
export const camera = require("../assets/imagebutton_camera.png");
export const profilePlaceholder = require("../assets/profile_placeholder.png");