export const imageHerosectionbackground = require("../assets/image_herosectionbackground.png");
export const imageShapeOne = require("../assets/image_shape (1).png");
export const imageShapeTwo = require("../assets/image_shape (2).png");
export const imageShapeThree = require("../assets/image_shape (3).png");
export const userImageOne = require("../assets/image_teammember (1).png");
export const userImageTwo = require("../assets/image_teammember (2).png");
export const userImageThree = require("../assets/image_teammember.png");
export const profile = require("../assets/imagebutton_profile.png");
export const oneImage = require("../assets/group_benefit1.png");
export const twoImage = require("../assets/group_benefit2.png");
export const threeImage = require("../assets/group_benefit3.png");
export const imgEllips = require("../assets/view_ellipse.png");
export const connectore = require("../assets/image_connectors.png");
export const logo = require("../assets/group_logo (1).png")
export const leftGroupFrame = require("../assets/group_text.png")
export const rightGroupFrame = require("../assets/group_text_down.png");
export const view_ellipseHalf = require("../assets/view_ellipseHalf.png");
export const textlabel_0 = require("../assets/textlabel_0.svg");
export const textlabel_1 = require("../assets/textlabel_1.svg");
export const textlabel_2 = require("../assets/textlabel_2.svg");
export const textlabel_3 = require("../assets/textlabel_3.svg");