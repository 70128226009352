import React from "react";

// Customizable Area Start
import {
  Paper,
  Grid,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import "./EmailAccountLogin.web.css"
import { imgF, imglogo } from "../../forgot-password/src/assets";
import { createTheme } from "@material-ui/core/styles";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Loader from "../../../components/src/Loader.web";
import { imgCheckbox } from "../../email-account-registration/src/assets";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      <Paper>
        <Loader loading={this.state.loading} />
        <Grid container>
        <Grid item xs={12} md={12} lg={6} style={{ background: 'linear-gradient(180deg, #0086F0 0%, #0069BC 100%)', display: 'flex', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <Box>
              <img src={imgF} style={{ width: '100%'}} />
            </Box>
            <Box style={{ display: 'flex', position: 'absolute', alignItems: 'flex-start', flexDirection: 'column', gap: '50px',paddingLeft:'20px' }}>
              <Typography style={{ fontSize: '32px', fontWeight: 500, color: '#F2F2F2' }} >Welcome to</Typography>
              <img src={imglogo} style={{
                height: 'auto',
                width: 'auto',
                maxWidth: '176px',
                maxHeight: '64px',
              }}/>
              <Typography style={{ fontSize: '48px', maxWidth: '560px', width: '100%', fontWeight: 800, color: '#F2F2F2', fontFamily: 'Lato' }} >Seamless Investment Journey</Typography>
              <Typography style={{ fontSize: '18px', maxWidth: '560px', color: '#F2F2F2', width: '100%' }} >Select, Allocate, Authenticate, and Transfer. </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className='rightSideContainer'>
            <Box className='rightContainer' >
              <Box style={{ maxWidth: "370px", width: '100%', textAlign: "justify" }}>
                <Typography style={{ fontWeight: 800, fontSize: "40px", margin: "16px 0px", fontFamily: "Lato" }} >Sign In</Typography>
              </Box>
              <FormControl variant="outlined" style={{ margin: "16px 0px" }}>
                <FormHelperText
                  id="email-helper-text"
                  style={{ fontWeight: 400, fontSize: "14px", margin: "0px", color: "#222222", fontFamily: "Roboto" }}
                >
                  Email
                </FormHelperText>
                <OutlinedInput
                  id="email-input"
                  value={this.state.webEmail}
                  onChange={this.handleEmailChange}
                  aria-describedby="email-helper-text"
                  labelWidth={0}
                  className="inputMainBox"
                  style={{
                    color: this.state.emailError ? "#DC2626" : "",
                  }}
                  onFocus={() => this.setState({ emailError: "" })}
                  onBlur={this.validateEmail}
                />
                {this.state.emailError && (
                  <FormHelperText style={{ color: "#DC2626", fontSize: "14px", fontWeight: 400, margin: "0px", maxWidth: "370px", width: '100%' }}>
                    {this.state.emailError}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl variant="outlined" style={{ margin: "16px 0px" }}>
                <FormHelperText
                  id="password-helper-text"
                  style={{ fontWeight: 400, fontSize: "14px", margin: "0px", color: "#222222", fontFamily: "Roboto" }}
                >
                  Password
                </FormHelperText>
                <OutlinedInput
                  type={this.state.showPassword ? "text" : "password"}
                  id="password-input"
                  onChange={this.handlePasswordChange}
                  value={this.state.webPassword}
                  labelWidth={0}
                  aria-describedby="password-helper-text"
                  className="inputMainBox"
                  style={{
                    color: this.state.passwordError ? "#DC2626" : "",
                  }}
                  onBlur={this.validatePassword}
                  onFocus={() => this.setState({ passwordError: "" })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.togglePassword}
                        aria-label="toggle password visibility"
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {this.state.passwordError && (
                  <FormHelperText style={{ color: "#DC2626", fontSize: "14px", fontWeight: 400, margin: "0px", maxWidth: "370px", width: '100%' }}>
                    {this.state.passwordError}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControlLabel
                data-test-id="forgot-form-label"
                control={this.state.checkBox ? <img src={imgCheckbox} alt="checkbox" onClick={this.handleCheckboxChange} style={{ margin: '2px 8px 0px 25px', height: '22px', width: '22px' }} /> :  <CheckBoxOutlineBlankIcon onClick={this.handleCheckboxChange} style={{ margin: '0px 8px 0px 25px' }}/>}
                label={
                  <span style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '335px', width: '100%' }}>
                    <Typography style={{ fontSize: "14px" ,fontFamily:'Roboto'}}>
                      Remember Me{" "}
                    </Typography>
                    <Typography style={{ fontSize: "14px" }}>
                    <span style={{ color: "#6F6F70", fontFamily: 'Roboto', textDecoration:'underline', fontWeight:"500" }} onClick={() => this.navigateTo('ForgotPassword')} data-test-id="forgotPassword"> Forgot Password? </span>                    </Typography>
                  </span>
                }
                className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                style={{ width: '100%', maxWidth: '335px' }}
              />

              <Button onClick={this.handleLoginSubmit}
                style={{
                  backgroundColor: '#0069BC',
                  padding: '10px 16px',
                  maxWidth: '370px',
                  width: '100%',
                  textTransform: 'none',
                  height: '60px',
                  borderRadius: '12px',
                  fontSize: '20px',
                  fontWeight: '700',
                  color: '#FFFFFF',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  margin: '16px 0px',
                  fontFamily:'Roboto'
                }}
              >
                Sign In
              </Button>
              <Typography style={{ fontWeight: '400', fontSize: '14px', alignSelf: 'center', margin: '16px 0px',fontFamily:'Roboto' }}>OR</Typography>
              <GoogleOAuthProvider clientId="539417259186-jd2q75art61ah202jjjim4dmegave6nm.apps.googleusercontent.com">
                <GoogleLogin
                  text='continue_with'
                  width='370px'
                  logo_alignment='center'
                  theme='outline'
                  shape="rectangular"
                  onSuccess={(credentialResponse) => this.loginWithGoogle(credentialResponse)}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                ></GoogleLogin>
              </GoogleOAuthProvider>
              <Typography style={{ fontWeight: '400', fontSize: '14px', alignSelf: 'center', margin: '16px 0px' ,fontFamily:'Roboto' }}>
                Don't have an account?{" "}
                <span style={{ color: "#0069BC", textDecoration: 'none' ,fontFamily:'Roboto', fontWeight:"600", cursor: 'pointer'}} onClick={() => this.navigateTo('EmailAccountRegistrationWeb')} data-test-id="signupBtn">Sign Up</span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
       // Customizable Area End
    );
  }
}
