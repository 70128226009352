import React from "react";

// Customizable Area Start

import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { imgF, imgLeftResetSuccessScreen, imglogo } from "./assets";
import Loader from "../../../components/src/Loader.web";

import {
    Paper,
    Grid,
    Box,
    Button,
    Typography,
    StyleRules,
    withStyles,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// Customizable Area End

import ForgotPasswordWebController, {
    Props,
} from "./ForgotPasswordController.web";

const styles: StyleRules = {
    // Customizable Area Start
    RightContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
        '@media (max-width: 425px)': {
            width: '310px',
        }
    },
    textStyle: {
        fontWeight: 400,
        fontSize: "16px",
        margin: "16px 0px",
        fontFamily: 'Roboto',
        maxWidth: '350px',
        width: '100%',
    },
    headText: {
        fontWeight: 800,
        fontSize: "40px",
        margin: "16px 0px",
        color: '#0069BC',
        whiteSpace: 'nowrap',
        fontFamily: 'Lato',
        '@media (max-width: 425px)': {
            width: '270px',
            fontSize: "35px"
        }
    },
    errTxt: {
        fontWeight: 400,
        color: "#DC2626",
        maxWidth: "370px",
        width: '100%',
        fontSize: "14px",
        margin: "0px",
    },
    lblTxt: {
        margin: "0px",
        fontSize: "14px",
        color: "#222222",
        fontWeight: 400,
        fontFamily: "Roboto",
    },
    btn: {
        backgroundColor: "#0069BC",
        padding: "10px 16px",
        textTransform: "none",
        width: "100%",
        maxWidth: '370px',
        height: "60px",
        borderRadius: "12px",
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: 700,
        margin: "16px 0px",
        "&:hover": {
            backgroundColor: "#0069BC",
        },
    },
    desriptionMainText: {
        fontSize: '18px',
        fontWeight: 500,
        maxWidth: '560px',
        width: '100%',
        color: '#F2F2F2',
        '@media (max-width: 890px)': {
            textAlign: "center"
        }
    },
    imageBox: {
        position: 'absolute',
        height: '100vh',
        width: "100vh",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 425px)': {
            width: '100%',
        }
    },
    leftSieMainContainer: {
        display: 'flex',
        position: 'absolute',
        top: '15%',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '50px', 
        '@media (max-width: 890px)': {
            alignItems: "center"
        }
    },
    titleMainBox: {
        maxWidth: "370px",
        width: '100%',
        textAlign: "justify",
    },
    inputMainBox: {
        width: "370px",
        height: "48px",
        borderRadius: "8px",
        '@media (max-width: 425px)': {
            width: '280px',
        }
    },
    rightSider: {
        display: 'flex', 
        minHeight: '100vh',
        justifyContent: 'center', 
        '@media (max-width: 890px)': {
            minHeight: '40vh', 
        }
    },
    leftSider: {
        background: 'linear-gradient(180deg, #0086F0 0%, #0069BC 100%)', 
        minHeight: '100vh', 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center', 
        '@media (max-width: 890px)': {
            minHeight: '60vh', 
        }
    },
    // Customizable Area End
};

export class ResetPasswordWeb extends ForgotPasswordWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { loading } = this.state;
        // Customizable Area End

        return (
            // Customizable Area Start
            <Paper>
                <Loader loading={loading} />
                <Grid container>
                    <Grid item xs={12} md={12} lg={6} className={classes.leftSider}>
                        <Box>
                            <img style={{ width: '100%' }} src={imgF} alt="poster" />
                        </Box>
                        <Box className={classes.leftSieMainContainer} >
                            <Typography style={{ fontSize: '32px', fontWeight: 500, color: '#F2F2F2' }} >Welcome to</Typography>
                            <img src={imglogo} alt="screen"  style={{
                                maxWidth: '176px',
                                maxHeight: '64px',
                                width: 'auto',
                                height: 'auto',
                            }} />
                            <Typography className={classes.desriptionMainText} >For security purposes, we recommend using a unique password that you haven't used for any other online accounts</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} className={classes.rightSider}>
                        {this.state.resetPasswordSuccess ?
                            <Box className={classes.RightContainer}>
                                <img src={imgLeftResetSuccessScreen} alt="success" />
                                <Box className={classes.titleMainBox} >
                                    <Typography className={classes.headText}>Password Reset!</Typography>
                                    <Typography className={classes.textStyle}>Your password has been reset successfully.</Typography>
                                </Box>

                                <Button onClick={() => window.location.href = "/EmailAccountLoginWeb"} className={classes.btn}>
                                    Login to continue
                                </Button>
                            </Box> :
                            <Box className={classes.RightContainer}>
                                <Box className={classes.titleMainBox} >
                                    <Typography className={classes.headText}>Reset password</Typography>
                                    <Typography className={classes.textStyle}>Set the new password for your account to login and access all the features</Typography>
                                </Box>
                                <FormControl variant="outlined">
                                    <FormHelperText
                                        id="password-helper-text"
                                        className={classes.lblTxt}
                                    >
                                        New Password
                                    </FormHelperText>
                                    <OutlinedInput
                                        id="password-input"
                                        type={this.state.showNewPassword ? "text" : "password"}
                                        value={this.state.newPassword}
                                        onChange={this.handleNewPasswordChange}
                                        aria-describedby="password-helper-text"
                                        labelWidth={0}
                                        className={classes.inputMainBox}
                                        style={{
                                            color: this.state.newPasswordError ? "#DC2626" : "",
                                        }}
                                        onFocus={() => this.setState({ newPasswordError: "" })}
                                        onBlur={this.validateNewPassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowNewPassword}
                                                    onMouseDown={this.handleMouseDownNewPassword}
                                                >
                                                    {this.state.showNewPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {this.state.newPasswordError && (
                                        <FormHelperText className={classes.errTxt}>
                                            {this.state.newPasswordError}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl variant="outlined">
                                    <FormHelperText
                                        id="password-helper-text"
                                        className={classes.lblTxt}
                                    >
                                        Confirm New Password
                                    </FormHelperText>
                                    <OutlinedInput
                                        id="password-input"
                                        type={this.state.showConfirmPassword ? "text" : "password"}
                                        value={this.state.confirmPassword}
                                        onChange={this.handleConfirmPasswordChange}
                                        aria-describedby="password-helper-text"
                                        labelWidth={0}
                                        className={classes.inputMainBox}
                                        style={{
                                            color: this.state.confirmPasswordError ? "#DC2626" : "",
                                        }}
                                        onFocus={() => this.setState({ confirmPasswordError: "" })}
                                        onBlur={this.validateConfirmPassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowConfirmPassword}
                                                    onMouseDown={this.handleMouseDownConfirmPassword}
                                                >
                                                    {this.state.showConfirmPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {this.state.confirmPasswordError && (
                                        <FormHelperText className={classes.errTxt}>
                                            {this.state.confirmPasswordError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <Button onClick={this.handleResetSubmit} className={classes.btn}>
                                    Continue
                                </Button>
                            </Box>}
                    </Grid>
                </Grid>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(ResetPasswordWeb);
// Customizable Area End
