import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  loading: boolean;
  investorDataRecords: any;
  companyData: any;
  selectedCompanyType: string;
  fullName: string;
  email: string;
  currentIndex: number;
  currentIndex2: number;
  interval: any;
  token: any;
  fullName_error: string;
  email_error: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInvestoresApiCallId: string = '';
  getCompaniesApiCallId: string = '';
  submitWaitingListApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      open: false,
      loading: false,
      investorDataRecords: [],
      companyData: [],
      selectedCompanyType: 'startup',
      fullName: '',
      email: '',
      currentIndex: 0,
      currentIndex2: 0,
      interval: null,
      token: null,
      fullName_error: '',
      email_error: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getInvestoresApiCallId) {
        this.setState({ loading: false });
        if (responseJson.data) {
          this.setState({
            investorDataRecords: responseJson.data
          });
        }
      } else if (apiRequestCallId === this.getCompaniesApiCallId) {
        this.setState({ loading: false });
        if (responseJson.data) {
          this.setState({
            companyData: responseJson.data
          });
        }
      } else if (apiRequestCallId === this.submitWaitingListApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
          toast.success(responseJson.meta.message);
          this.handleClose();
        } else {
          toast.error(responseJson.errors[0]);
          this.handleClose();
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getInvestores()
    this.getCompanies("startup")
    this.setState({
      interval: setInterval(() => {
        this.setState((prevState) => ({
          currentIndex: (prevState.currentIndex + 1) % this.state.investorDataRecords.length,
          currentIndex2: (prevState.currentIndex2 + 1) % this.state.companyData.length,
        }));
      }, 1000),
    });
    const getToken = await getStorageData("token")
    this.setState({
      token: getToken
    })    
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  handleOpen = () => {
    this.setState({
      open: true,
      fullName_error:'',
      email_error: ''
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      email: '',
      fullName: '',
    });
  };

  handleSubmit = () => {
    const isValidFullname = this.validateFullName();
    const isvalidateEmail = this.validateEmail();
    if (!isValidFullname || !isvalidateEmail) {
      return;
    } else {
      this.submitWaitingList();
      this.setState({
        email: '',
        fullName: '',
      });
    }
  };

  handleFullnameChange = (event: any) => {
    this.setState({
      fullName: event.target.value
    })
  }

  handleScheduleCall = () => {
    window.open('https://calendly.com/futureforalts/30min', "_blank","noopener")
  }

  handleInvestNow = () => {
    window.location.href = "/InvestmentOpportunitiesWeb/all"
  }

  handleEmailChange = (event: any) => {
    this.setState({
      email: event.target.value
    })
  }

  submitWaitingList = () => {
    this.setState({ loading: true });

    const header = { "Content-Type": "application/json" };
    const data = {
      "attributes": {
        "full_name": this.state.fullName,
        "email": this.state.email
      }
    }
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.submitWaitingListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/user_waitlists");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInvestores = () => {
    this.setState({ loading: true });
    const header = { "Content-Type": "application/json" };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getInvestoresApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/co-investors");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompanies = (fundType: string) => {
    this.setState({ loading: true, selectedCompanyType: fundType });
    const header = { "Content-Type": "application/json" };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCompaniesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/companies?type=" + fundType);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validateFullName = () => {
    const { fullName } = this.state;
    if (!fullName) {
      this.setState({ fullName_error: "Full name is required" });
      return false;
    }
    this.setState({ fullName_error: "" });
    return true;
  };

  validateEmail = () => {
    const { email } = this.state;
    if (!email) {
      this.setState({ email_error: "Email is required" });
      return false;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      this.setState({ email_error: "Invalid email address" });
      return false;
    }
    this.setState({ email_error: "" });
    return true;
  };

  // Customizable Area End
}
