import React from "react";

// Customizable Area Start

import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { imgF, imglogo } from "./assets";
import Loader from "../../../components/src/Loader.web";

import {
    Paper,
    Grid,
    Box,
    Button,
    Typography,
    StyleRules,
    withStyles,
} from "@material-ui/core";

// Customizable Area End

import ForgotPasswordWebController, {
    Props,
} from "./ForgotPasswordController.web";

const styles: StyleRules = {
    // Customizable Area Start
    rightContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
        '@media (max-width: 425px)': {
            width: '310px',
        }
    },
    textStyle: {
        fontWeight: 400,
        fontSize: "16px",
        margin: "16px 0px",
        fontFamily: 'Roboto',
        maxWidth: '350px',
        width: '100%',
    },
    headerText: {
        fontWeight: 800,
        fontSize: "40px",
        margin: "16px 0px",
        color: '#0069BC',
        whiteSpace: 'nowrap',
        fontFamily: 'Lato',
        '@media (max-width: 425px)': {
            width: '270px',
            fontSize: "35px"
        }
    },
    errorTxt: {
        color: "#DC2626",
        fontWeight: 400,
        fontSize: "14px",
        maxWidth: "370px",
        width: '100%',
        margin: "0px",
    },
    labelTxt: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#222222",
        margin: "0px",
        fontFamily: 'Roboto'
    },
    btn: {
        padding: "10px 16px",
        backgroundColor: "#0069BC",
        width: "100%",
        textTransform: "none",
        height: "60px",
        maxWidth: '370px',
        color: "#FFFFFF",
        borderRadius: "12px",
        fontWeight: 700,
        fontSize: "20px",
        "&:hover": {
            backgroundColor: "#0069BC",
        },
        margin: "16px 0px",
    },
    desriptionMainText: {
        fontWeight: 500,
        fontSize: '18px',
        width: '100%',
        maxWidth: '560px',
        color: '#F2F2F2',
        '@media (max-width: 890px)': {
            textAlign: "center"
        }
    },
    imageBox: {
        height: '100vh',
        position: 'absolute',
        display: 'flex',
        width: "100vh",
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 425px)': {
            width: '100%',
        }
    },
    fnameLogo: {
        width: '100%'
    },
    leftSieMainContainer: {
        display: 'flex',
        position: 'absolute',
        top: '15%',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding:'0px 12px',
        gap: '50px',
        '@media (max-width: 890px)': {
            alignItems: "center"
        }
    },
    leftSide: {
        background: 'linear-gradient(180deg, #0086F0 0%, #0069BC 100%)', 
        display: 'flex', 
        minHeight: '100vh', 
        justifyContent: 'center', 
        alignItems: 'center',
        '@media (max-width: 890px)': {
            minHeight: '60vh', 
        }
    },
    titleMainBox: {
        maxWidth: "370px",
        width: '100%',
        textAlign: "justify",
    },
    inputMainBox: {
        width: "370px",
        height: "48px",
        borderRadius: "8px",
        '@media (max-width: 425px)': {
            width: '280px',
        }
    },
    rightSide: {
        display: 'flex', 
        justifyContent: 'center', 
        minHeight: '100vh',
        '@media (max-width: 890px)': {
            minHeight: '40vh', 
        }
    }
    // Customizable Area End
};

export class ForgotPasswordWeb extends ForgotPasswordWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <Paper>
                <Loader loading={this.state.loading} />
                <Grid container>
                    <Grid item xs={12} md={12} lg={6} className={classes.leftSide} >
                        <Box>
                            <img className={classes.fnameLogo} src={imgF} alt="poster" />
                        </Box>
                        <Box className={classes.leftSieMainContainer} >
                            <Typography style={{ fontSize: '32px', fontWeight: 500, color: '#F2F2F2' }} >Welcome to</Typography>
                            <img src={imglogo} alt="poster"  style={{
                                width: 'auto',
                                maxWidth: '176px',
                                height: 'auto',
                                maxHeight: '64px',
                            }}  />
                            <Typography className={classes.desriptionMainText} >Please check your spam folder if you don't receive the password reset email within a few minutes</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} className={classes.rightSide} >
                        <Box className={classes.rightContainer}>
                            <Box className={classes.titleMainBox}>
                                <Typography className={classes.headerText}>Forgot password?</Typography>
                                <Typography className={classes.textStyle}>Enter your email for the verification process, we will send you a link to your email.</Typography>
                            </Box>
                            <FormControl variant="outlined">
                                <FormHelperText
                                    id="email-helper-text"
                                    className={classes.labelTxt}
                                >
                                    Email
                                </FormHelperText>
                                <OutlinedInput
                                    id="email-input"
                                    onChange={this.handleEmailChange}
                                    value={this.state.email}
                                    labelWidth={0}
                                    aria-describedby="email-helper-text"
                                    onBlur={this.validateEmail}
                                    className={classes.inputMainBox}
                                    style={{
                                        color: this.state.emailError ? "#DC2626" : "",
                                    }}
                                    onFocus={() => this.setState({ emailError: "" })}
                                />
                                {this.state.emailError && (
                                    <FormHelperText className={classes.errorTxt}>
                                        {this.state.emailError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Button onClick={this.handleSubmit} className={classes.btn}>
                                Continue
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(ForgotPasswordWeb);
// Customizable Area End
