import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    investmentDone: boolean;
    introductoryProfileOpen: boolean;
    token: any;
    portfolioDetails: any;
    portfolioInvestmentList: any;
    selectedViewDetails: any;
    userName: any;
    loading: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PortfolioManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getPortfolioManagementDetailsApiCallId: string = '';
    getPortfolioInvestmentDetailsApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            investmentDone: true,
            introductoryProfileOpen: false,
            token: '',
            portfolioDetails: [],
            portfolioInvestmentList: [],
            selectedViewDetails: {},
            userName:'user',
            loading: true,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getPortfolioManagementDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        portfolioDetails: responseJson.data,
                    })
                }
            } else if (apiRequestCallId === this.getPortfolioInvestmentDetailsApiCallId) {
                if (responseJson) {
                    this.setState({
                        portfolioInvestmentList: responseJson.data,
                        loading: false
                    })
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getPortfolioManagementDetails();
        this.getPortfolioInvestmentDetails();
        this.setState({
            token: await getStorageData("token"),
            userName : await getStorageData("userName")
        })
        // Customizable Area End
    }

    // Customizable Area Start
    viewProfile = (detail: any) => {
        this.setState({
            introductoryProfileOpen: true,
            selectedViewDetails: detail
        })
    }

    closeProfile = () => {
        this.setState({
            introductoryProfileOpen: false
        })
    }

    formatDate(dateString: string) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const date = new Date(dateString);
        const month = months[date.getMonth()];
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);

        return `${day} ${month}'${year}`;
    }

    navigetoInvestment = () => {
        window.location.href = "/InvestmentOpportunitiesWeb/all"
    }

    getPortfolioManagementDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getPortfolioManagementDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/portfolio");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPortfolioInvestmentDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getPortfolioInvestmentDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/investments");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    numberWithCommas = (numberValue: any) => {
        if (numberValue) {
            const partition = numberValue.toString().split('.');
            partition[0] = partition[0].replace(new RegExp(configJSON.commaExpression, 'g'), '$&,');
            return partition.join('.');
        }
        return numberValue
    }
    // Customizable Area End
}
