export const navLogo = require('../assets/group_logo_blue.png')
export const navProfile = require('../assets/imagebutton_profile.png')
export const viewtrectangle = require('../assets/view_rectangle.png')
export const groupcoinvestors = require('../assets/group_coinvestors.png')
export const view_rectangle_black = require('../assets/view_rectange_black.png')
export const group_shape = require('../assets/group_shape.png')
export const shape = require('../assets/shape.png')
export const investorLogo1 = require('../assets/image_logo1.png')
export const investorLogo2 = require('../assets/image_logo2.png')
export const investorLogo3 = require('../assets/image_logo3.png')
export const logoImg1 = require('../assets/image_logo5.png')
export const logoImg2 = require('../assets/image_logo6.png')
export const logoImg3 = require('../assets/image_logo7.png')
export const logoImg4 = require('../assets/image_logo8.png')
export const logoImg5 = require('../assets/image_logo9.png')
export const logoImg6 = require('../assets/image_logo10.png')
export const viewLine = require('../assets/view_line.png')
export const viewPolygon = require('../assets/view_polygon.png')
export const imgArraow = require('../assets/image_arrow.png')
export const imgBargraph = require('../assets/group_bargraph.png')
export const imgReport = require('../assets/image_report.png')
export const imgShape = require('../assets/image_shape (4).png')
export const imgGroupSection = require('../assets/group_section.png')
export const image_shape = require('../assets/image_shape (5).png')
export const frameShape= require('../assets/Frame 13965.png')
export const group_text02 = require('../assets/group_text02.png')